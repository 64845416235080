import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import helpers from '../../helpers/price-helper';
import '../../styles/price-format.style.scss';

const PriceFormat = ({
  price,
  priceBadge,
  isSmallBadge,
  isSmallSize,
  hasStrikeThrough,
  basic,
  hideBadge
}) => {
  const priceObj = helpers.parsePriceValue(price);
  const badgeClasses = classNames({
    'small-badge': isSmallBadge,
    'price-format__badge': true,
    'price-format__badge-align': !isSmallBadge,
  });
  const priceClass = classNames({
    'price-format__large': !isSmallSize,
    'price-format__main-price': true,
    'price-format--basic': basic
  });
  const symbolClass = classNames({
    'price__format-style': isSmallSize,
    'price-format__large-currency-symbols': !isSmallSize,
  });
  const strikeClass = classNames({
    'price-format__strikethrough-up': !priceBadge || hideBadge,
    'price-format__strikethrough-up-above': !isSmallSize,
  });
  if (!priceObj) return null;
  if (basic) {
    return (
      <div className="price">
        <span className={priceClass}>
          ${price}
        </span>
      </div>
    );
  }
  return (
    <div className="price">
      {priceBadge && !hideBadge && <div className={`value-price-badge__${priceBadge} ${badgeClasses}`} />}
      {priceObj.dollars !== '0' && (
        <div className={priceClass}>
          <span className={symbolClass}>$</span>
          <span>{priceObj.dollars}</span>
          <span className="sui-sr-only">.</span>
          <span className={symbolClass}>{priceObj.cents}</span>
          {hasStrikeThrough && <span className={`price-format__strikethrough ${strikeClass}`} />}
        </div>
      )}
      {priceObj.dollars === '0' && priceObj.cents !== '00' && (
        <div className={priceClass}>
          <span className={priceClass}>{priceObj.cents}</span>
          <span className={symbolClass}>¢</span>
        </div>
      )}
      {priceObj.dollars === '0' && priceObj.cents === '00' && (
        <div className={priceClass}>
          <span className={symbolClass}>$</span>
          <span>{priceObj.dollars}</span>
          <span class="sui-sr-only">.</span>
          <span className={symbolClass}>{priceObj.cents}</span>
        </div>
      )}
    </div>
  );
};

PriceFormat.propTypes = {
   /**
   * To show the price of the product
   */
  price: PropTypes.string,
   /**
   * To show the price badge
   */
  priceBadge: PropTypes.string,
   /**
   * To confirm the badge size
   */
  isSmallBadge: PropTypes.bool,
   /**
   * To confirm the size
   */
  isSmallSize: PropTypes.bool,
   /**
   * To confirm if it has Strike
   */
  hasStrikeThrough: PropTypes.bool,
   /**
   * To the basic formate price
   */
  basic: PropTypes.bool,
   /**
   * To hide the Badge
   */
  hideBadge: PropTypes.bool
};

PriceFormat.defaultProps = {
  price: null,
  priceBadge: null,
  isSmallBadge: true,
  isSmallSize: true,
  hasStrikeThrough: false,
  basic: false,
  hideBadge: false
};

export default PriceFormat;
