/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const renderChildren = (children) => {
  if (!children) {
    return <></>;
  }

  const elements = Array.isArray(children) ? children : [children];
  return <>{elements.map((child) => child)}</>;
};

export const PodSection = ({
  children, columnAlign, noPadding, relative, margin, alignTop
}) => {
  const podSectionClasses = [
    'sui-flex',
    `${columnAlign ? 'sui-flex-col' : ''}`,
    `${noPadding ? 'sui-p-0' : 'sui-p-2'}`,
    `${relative ? 'sui-relative' : ''}`,
    `${margin ? 'sui-m-2' : ''}`,
    `${alignTop ? 'sui-justify-start' : ''}`
  ].join(' ');

  return (
    <div
      className={podSectionClasses}
      data-testid="pod-section"
    >
      {renderChildren(children)}
    </div>
  );
};

PodSection.propTypes = {
  columnAlign: PropTypes.bool,
  noPadding: PropTypes.bool,
  relative: PropTypes.bool,
  margin: PropTypes.bool,
  alignTop: PropTypes.bool,
};
PodSection.defaultProps = {
  columnAlign: false,
  noPadding: false,
  relative: false,
  margin: false,
  alignTop: false,
};

export const PodHeader = ({ children }) => (
  <div
    className="sui-flex sui-py-0 sui-px-2"
    data-testid="pod-header"
  >
    {renderChildren(children)}
  </div>
);

export const PodColumn = ({ children, minWidth, noPadding }) => {
  const podColumnClasses = [
    'sui-flex sui-flex-col sui-relative',
    `${noPadding ? 'sui-p-0' : 'sui-p-1'}`,
  ].join(' ');

  return (
    <div
      className={podColumnClasses}
      style={{ minWidth }}
      data-testid="pod-column"
    >
      {renderChildren(children)}
    </div>
  );
};

/**
 * Dynamic Pod wrapper that displays child elements in a Row on small screens,
 * and automatically shifts to display children in a Column on med. to lg. screens.
 * @param children - child elements to be displayed in row or column
 * @param minWidth - set minWidth style on top level wrapper element
 * @param noGap - remove default sui-gap-1
 * @returns {Element}
 */
export const PodRowToColumn = ({ children, minWidth, noGap }) => {

  //    'sui-flex sui-grow sui-shrink-0 sui-basis-0 md:sui-flex-col lg:sui-flex-col',
  const podRowClasses = [
    'sui-grid sui-grid-cols-2 md:sui-grid-cols-1 lg:sui-grid-cols-1',
    `${noGap ? 'sui-gap-0' : 'sui-gap-1'}`,
  ].join(' ');

  return (
    <div
      className={podRowClasses}
      style={{ minWidth }}
      data-testid="pod-column"
    >
      {renderChildren(children)}
    </div>
  );
};

export const PodFooter = ({ children, noPadding }) => (
  <div
    className="sui-flex-none sui-mt-auto sui-p-2"
    style={{ padding: noPadding && 0 }}
    data-testid="pod-footer"
  >
    {renderChildren(children)}
  </div>
);

// Note: position prop default is 'top-left'; but position can also be 'top-right'.
export const PodSticker = ({ children, position = 'top-left' }) => {
  const podStickerClasses = [
    'sui-absolute sui-flex sui-z-10',
    `${position === 'top-left' ? 'sui-left-0' : 'sui-right-0'}`
  ].join(' ');

  return (
    <div
      className={podStickerClasses}
      data-testid="pod-sticker"
    >
      {renderChildren(children)}
    </div>
  );
};

export const PodSpacer = ({ children, padding, minHeight, disable }) => {
  if (disable) return null;

  const style = minHeight ? { minHeight } : {};

  const podSpacerClasses = [
    `${padding.includes('ALL') ? 'sui-p-1' : ''}`,
    `${padding.includes('LEFT') ? 'sui-pl-1' : ''}`,
    `${padding.includes('RIGHT') ? 'sui-pr-1' : ''}`,
    `${padding.includes('TOP') ? 'sui-pt-1' : ''}`,
    `${padding.includes('BOTTOM') ? 'sui-pb-1' : ''}`
  ].join(' ');

  return (
    <div
      className={podSpacerClasses}
      style={style}
      data-testid="pod-spacer"
    >
      {renderChildren(children)}
    </div>
  );
};

PodSpacer.propTypes = {
  padding: PropTypes.arrayOf(PropTypes.oneOf(['LEFT', 'RIGHT', 'TOP', 'BOTTOM', 'ALL'])),
  // see Stencil Design System > Foundations > Spacing for correct values
  minHeight: PropTypes.string,
};
PodSpacer.defaultProps = {
  padding: [],
  minHeight: null,
};
