import {
  APPLIANCE_URL_PATH,
  APPLIANCE_DEPARTMENT,
  CUSTOMER_TYPES,
  FULFILLMENT_METHODS,
  LONG_MONTH_NAMES,
  LONG_WEEK_NAMES,
  MAJOR_APPLIANCE,
  MAJOR_APPLIANCE_DELIVERY,
  MERCHANDISE,
  MORE_DELIV_OPTIONS_TEXT,
  NON_MAJOR_APPLIANCE_CLASS,
  SHORT_MONTH_NAMES,
  SHORT_WEEK_NAMES,
  DEFAULT_PRODUCTION_URL_ORIGIN,
} from '../constants';
import { getStateName } from './StateCodes';

export const formatDate = (date, isLongMonthName) => {
  if (!date) {
    return date;
  }
  let formattedDate;
  if (typeof date === 'object') {
    formattedDate = date;
  } else if (typeof date === 'string' && date.indexOf('T') > -1) {
    formattedDate = new Date(date);
  } else {
    const dateparts = date.split('-');
    formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
  }

  if (formattedDate.toString() === 'Invalid Date') {
    return null;
  }
  if (isLongMonthName) {
    return LONG_MONTH_NAMES[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
  }
  return SHORT_MONTH_NAMES[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
};

export const isMycartPage = () => {
  return (typeof document !== 'undefined') ? ((document.location.href.indexOf('mycart') > -1)
    || (document.location.href.indexOf('/cart') > -1)) : false;
};

export const replaceAllSpaces = (str, replacedCharacter) => {
  try {
    return str?.replace(/[" "]/g, replacedCharacter);
  } catch (exception) {
    return str;
  }
};

export const formatWeek = (date, isLongDayName, displayDate) => {
  if (!date) {
    return `${date}, ${displayDate}`;
  }
  let formattedDate;
  if (typeof date === 'object') {
    formattedDate = date;
  } else if (typeof date === 'string' && date.indexOf('T') > -1) {
    formattedDate = new Date(date);
  } else {
    const dateparts = date.split('-');
    formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
  }
  if (formattedDate.toString() === 'Invalid Date') {
    return null;
  }

  const nowrapDisplayDate = isMycartPage() ? replaceAllSpaces(displayDate, '\u00a0') : displayDate;
  if (isLongDayName) {
    return `${LONG_WEEK_NAMES[formattedDate.getDay()]}, ${nowrapDisplayDate}`;
  }
  return `${SHORT_WEEK_NAMES[formattedDate.getDay()]}, ${nowrapDisplayDate}`;
};

export const safelyGet = (input, fallback) => {
  try {
    return input();
  } catch (exception) {
    return fallback;
  }
};

// This method is also used in DeliveryDetails Drawer to display the timelines
export const showTodayOrTomorrow = (date, isLongDayName = false, deliveryTimeline, displayDate) => {
  const delTimeline = deliveryTimeline?.toLowerCase();
  if ((delTimeline === 'today' || delTimeline === 'tomorrow') && isLongDayName) {
    return deliveryTimeline[0].toUpperCase() + deliveryTimeline.substring(1).toLowerCase();
  }
  if (!date) {
    return `${displayDate}`;
  }
  const dateparts = date.split('-');
  const estimatShippingMonth = parseInt(dateparts[1], 10);
  const estimatShippingDay = parseInt(dateparts[2], 10);

  const todaysDate = new Date();
  const today = todaysDate.getDate();
  const todayMonth = todaysDate.getMonth() + 1;

  const tomorrowDate = new Date(todaysDate.setDate(todaysDate.getDate() + 1));
  const tomorrow = tomorrowDate.getDate();
  const tomorrowMonth = tomorrowDate.getMonth() + 1;

  if (today === estimatShippingDay && todayMonth === estimatShippingMonth) {
    return 'Today';
  }
  if (tomorrow === estimatShippingDay && tomorrowMonth === estimatShippingMonth) {
    return 'Tomorrow';
  }
  return formatWeek(date, isLongDayName, displayDate);
};

// This method assumes that a date check for Today or Tomorrow was done,
// and that the two dates are different.
export const showDateOrDateRange = ({ formattedStartDate = '', formattedEndDate = '' }) => {
  if (formattedStartDate && formattedEndDate) {
    return `${formattedStartDate} - ${formattedEndDate}`;
  }
  if (!formattedStartDate && formattedEndDate) {
    return formattedEndDate;
  }
  if (formattedStartDate && !formattedEndDate) {
    return formattedStartDate;
  }
  return '';
};

export const getParsedFulfillment = (fulfillment, shippingServiceModel) => {
  if (shippingServiceModel) {
    const {
      dynamicETA: { totalHours, totalMinutes },
      endDate,
      startDate,
      freeShippingThreshold,
      deliveryTimeline,
      totalCharge,
      isBodfsShipMode,
      hasFreeShipping,
      locations
    } = shippingServiceModel || {};
    return {
      ...fulfillment,
      deliveryTimeline,
      deliveryCharge: totalCharge,
      isBodfsShipMode,
      freeDeliveryThreshold: freeShippingThreshold,
      hasFreeShipping,
      locations,
      deliveryDates: {
        ...fulfillment.deliveryDates,
        startDate,
        endDate
      },
      dynamicEta: {
        ...fulfillment.dynamicETA,
        hours: totalHours,
        minutes: Number.isNaN(totalMinutes) ? '' : totalMinutes
      }
    };
  }
  return fulfillment;
};

export const isStoreOnlyWithClearance = (fulfillment, type) => {
  return fulfillment?.anchorStoreStatusType?.toUpperCase() === 'CLEARANCE' && type === 'Store Only';
};

export const isActiveOrInSeason = (fulfillment) => {
  return (fulfillment?.anchorStoreStatusType?.toUpperCase() === 'ACTIVE'
  || fulfillment?.anchorStoreStatusType?.toUpperCase() === 'IN SEASON');
};

export const isOutOfStockOnline = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length) {
    return locations[0]?.inventory?.isOutOfStock;
  }
  return false;
};

export const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    return locations.find((location) => !location.isAnchor);
  }
  return null;
};

export const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  return locations.find((location) => location.isAnchor);
};

export const getOnlineLocation = (fulfillment) => {
  return (fulfillment?.locations || []).find((location) => location.type === 'online');
};

export const isBOSSBackordered = (fulfillment) => {
  return fulfillment?.backordered;
};

export const isBOSSOutOfStock = (fulfillment) => {
  const bossLocation = (fulfillment?.locations || []).find((location) => location.type === 'online');
  return bossLocation?.inventory?.isOutOfStock;
};

export const isBopisOutOfStock = (localStoreLocation, nearByStoreLocation) => {
  const localStoreOOS = localStoreLocation?.inventory?.isOutOfStock;
  const nearByStoreOOS = nearByStoreLocation?.inventory?.isOutOfStock;
  return !!(localStoreOOS || (localStoreOOS && nearByStoreOOS));
};

export const isBOPISUnavailable = (localStoreLocation, nearByStoreLocation) => {
  const localStoreUnavailable = localStoreLocation?.inventory?.isUnavailable;
  const nearByStoreUnavailable = nearByStoreLocation?.inventory?.isUnavailable;
  if (localStoreUnavailable || (localStoreUnavailable && nearByStoreUnavailable)) {
    return true;
  }
  return localStoreUnavailable;
};

export const isBOSSDominant = (bossFulfillment, location, quantity) => {
  return bossFulfillment
    && location?.inventory?.quantity > 0
    && location.inventory.quantity < quantity;
};

export const isCovidAlertEnabled = (covidAlert, bopisHolidayTiming) => {
  return covidAlert && new Date().getHours() >= bopisHolidayTiming;
};

export const getExcludedStateName = (stateCode) => {
  return getStateName(stateCode) || stateCode;
};

export const isLocationInExcludedStates = (stateCode, excludedStates = '') => {
  if (excludedStates && excludedStates.split) {
    const excludedStatesArray = excludedStates.split(',');
    return (stateCode && stateCode.length === 2 && excludedStatesArray.includes(stateCode));
  }
  return false;
};

export const getFulfillmentLabel = (fulfillment) => {
  if (fulfillment.method === FULFILLMENT_METHODS.STH) {
    return 'Ship to Home';
  }
  if (fulfillment.method === FULFILLMENT_METHODS.BOPIS) {
    return 'Store Pickup';
  }
  if (fulfillment.method === FULFILLMENT_METHODS.STORE) {
    return 'Ship to Store';
  }
  if (fulfillment.method === FULFILLMENT_METHODS.BODFS) {
    return 'Express Delivery';
  }
  return null;
};

export const getLiveGoodsLocations = (fulfillment) => {
  return safelyGet(() => (fulfillment?.fulfillmentOptions)
    .find((option) => (option.type === 'pickup' && option.fulfillable === false
      && option.services)).services
    .find((services) => (services.type === 'bopis' && services.locations)).locations);
};

export const getLiveGoodsStock = (fulfillment) => {
  if (fulfillment?.seasonStatusEligible == null) {
    return false;
  }

  const bopisLocations = getLiveGoodsLocations(fulfillment);

  if (bopisLocations) {
    return safelyGet(() => bopisLocations
      .find((location) => (location.isAnchor)).inventory.quantity, 0) || 0;
  }
  return false;
};

export const isQuantityLimitExceed = ({ method, storeObj, shippingObj, expressObj }) => {
  if (method === FULFILLMENT_METHODS.BOPIS || method === FULFILLMENT_METHODS.STORE) {
    return storeObj.quantityLimitExceed;
  }
  if (method === FULFILLMENT_METHODS.STH) {
    return shippingObj.quantityLimitExceed;
  }
  if (method === FULFILLMENT_METHODS.BODFS) {
    return method === shippingObj.method ? shippingObj.quantityLimitExceed : expressObj?.quantityLimitExceed;
  }
  return false;
};

export const isStoreOnlyOOS = (fulfillment, type) => {
  const pickupService = (fulfillment?.fulfillmentOptions || [])
    .find((option) => option.type === 'pickup' && !option.fulfillable);
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

  const localStoreLocation = getLocalStore(bopisFulfillment);
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  return ((isBopisOutOfStock(localStoreLocation, nearByStoreLocation)
    || isBOPISUnavailable(localStoreLocation, nearByStoreLocation))
      && type === 'Store Only');
};

export const bopis = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  return bopisFulfillment || null;
};
export const sth = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const sthFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  return sthFulfillment || null;
};
export const boss = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return bossFulfillment || null;
};
export const bodfs = (fulfillment) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const bodfsFulfillment = (deliveryService?.services || []).find((service) => service.type === 'express delivery');
  return bodfsFulfillment || null;
};

export const isBopisNotFulfillableOOS = (fulfillment) => {
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  if (bopisFulfillment && !pickupService.fulfillable) {
    const localStoreLocation = getLocalStore(bopisFulfillment);
    return localStoreLocation?.inventory?.quantity === 0;
  }
  return false;
};

export const isMajorApplianceProductType = (productType) => {
  return productType === MAJOR_APPLIANCE;
};

export const isMerchandiseProductType = (productType) => {
  return productType === MERCHANDISE;
};

export const isNonMajorAppliance = (productType, info) => {
  const departmentCheck = info?.productDepartment === APPLIANCE_DEPARTMENT;
  const classCheck = NON_MAJOR_APPLIANCE_CLASS.find((classNumberValue) => classNumberValue === info?.classNumber);
  return isMerchandiseProductType(productType)
    && departmentCheck
    && classCheck;
};

export const isNoBOPISForAppliance = (productType, fulfillment) => {
  const bopisFulfillment = bopis(fulfillment);
  if (!bopisFulfillment && isMajorApplianceProductType(productType)) {
    return true;
  }
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return isBopisOutOfStock(localStoreLocation) && isMajorApplianceProductType(productType);
};

export const hasNoDirectDeliveryAvailable = ({ productType, fulfillment, checkAvailabilityModel }) => {
  const noBOPIS = isNoBOPISForAppliance(productType, fulfillment);
  return (checkAvailabilityModel?.hasOutOfStock || checkAvailabilityModel?.isUnavailable) && noBOPIS;
};

export const getDeliveryZip = () => {
  let zipCode = '';
  try {
    if (typeof window !== 'undefined') {
      const zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
      zipCode = zipDetails?.zipcode;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return zipCode;
};
export const setDeliveryZip = (deliveryZipCode) => {
  try {
    if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer && deliveryZipCode) {
      window.THD_LOCALIZER_AUTO_INIT.Localizer.updateDeliveryZipcodeDetails(deliveryZipCode, 'USER');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const formatInventory = (inventoryValue) => {
  let inventorySplit = inventoryValue?.toLocaleString();
  if (!inventoryValue) {
    return 0;
  }
  return inventorySplit;
};

export const formatDistance = (distance = 0) => {
  return parseFloat(distance).toFixed(1);
};

export const getLiveGoodsStoreName = (product) => {
  const bopisLocations = getLiveGoodsLocations(product?.fulfillment);

  if (bopisLocations) {
    return safelyGet(() => bopisLocations
      .find((location) => (location.isAnchor)).storeName) || false;
  }
  return false;
};

export const getPickupStoreName = (product) => {
  let storeName = '';
  try {
    const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
    const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
    const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

    if (bopisFulfillment) {
      storeName = safelyGet(() => (bopisFulfillment?.locations || [])
        .find((location) => (location.isAnchor)).storeName) || false;
    }
    if (!storeName) {
      storeName = safelyGet(() => (bossFulfillment?.locations || [])
        .find((location) => (location.isAnchor)).storeName) || false;
    }
  } catch (error) {
    console.error(error);
  }
  return storeName;
};

export const getPickupStoreTimeZone = (fulfillment) => {
  try {
    const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
    const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
    const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

    if (bopisFulfillment) {
      return safelyGet(() => (bopisFulfillment?.locations || [])
        .find((location) => (location.isAnchor)).storeTimeZone) || false;
    }
    if (bossFulfillment) {
      return safelyGet(() => (bossFulfillment?.locations || [])
        .find((location) => (location.isAnchor)).storeTimeZone) || false;
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

export const getCurrentFulfillment = ({ selectedFulfillment, fulfillmentModels }) => {
  if (selectedFulfillment === FULFILLMENT_METHODS.BOPIS || selectedFulfillment === FULFILLMENT_METHODS.BOSS) {
    return fulfillmentModels.find(
      (option) => option.method === FULFILLMENT_METHODS.BOPIS || option.method === FULFILLMENT_METHODS.BOSS
    );
  }
  return fulfillmentModels.find((option) => option.method === selectedFulfillment);
};

export const isMobile = (channel) => {
  return channel?.toLowerCase() === 'mobile';
};
export const isDesktop = (channel) => {
  return channel?.toLowerCase() === 'desktop';
};

export const isB2C = (customer) => {
  return (customer?.type?.toUpperCase() === CUSTOMER_TYPES.B2C);
};

export const isB2B = (customer) => {
  return !!((customer?.type?.toUpperCase() === CUSTOMER_TYPES.B2B)
    || (customer?.type?.toUpperCase() === CUSTOMER_TYPES.B2X));
};

function getQueryParams(str) {
  if (!str) return {};
  if (/^\?/.test(str)) {
    // eslint-disable-next-line no-param-reassign
    str = str.substring(1, str.length);
  }
  return str.split('&').reduce((acc, cur) => {
    const [key, value] = cur.split('=');
    return {
      ...acc,
      [key]: value
    };
  }, {});
}

export const isTwoTile = () => {
  let _isTwoTile = false;
  try {
    if (typeof window !== 'undefined' && window?.cookieUtils?.readBrowserCookie('isTwoTileEnabled')) {
      const cookieValue = window?.cookieUtils?.readBrowserCookie('isTwoTileEnabled') || '';
      _isTwoTile = cookieValue.toLowerCase() === 'true';
      return _isTwoTile;
    }
    if (typeof window !== 'undefined' && window.location) {
      const queryParams = getQueryParams(window.location.search);
      _isTwoTile = !!queryParams.twoTile;
      return _isTwoTile;
    }
  } catch (error) {
    // console.error(error);
  }
  return _isTwoTile;
};

export const isTwoTileQuantityUpdateCookie = () => {
  let _isTwoTileQuantityUpdate = false;
  try {
    if (typeof window !== 'undefined' && window?.cookieUtils?.readBrowserCookie('isTwoTileQuantityUpdate')) {
      const cookieValue = window?.cookieUtils?.readBrowserCookie('isTwoTileQuantityUpdate') || '';
      _isTwoTileQuantityUpdate = cookieValue.toLowerCase() === 'true';
      return _isTwoTileQuantityUpdate;
    }
    if (typeof window !== 'undefined' && window.location) {
      const queryParams = getQueryParams(window.location.search);
      _isTwoTileQuantityUpdate = !!queryParams.isTwoTileQuantityUpdate;
      return _isTwoTileQuantityUpdate;
    }
  } catch (error) {
    // console.error(error);
  }
  return _isTwoTileQuantityUpdate;
};

export const isBackorderedSelection = (backordered, currentSelectedFulfillment) => {
  if (backordered && ((currentSelectedFulfillment === FULFILLMENT_METHODS.STH)
    || (currentSelectedFulfillment === FULFILLMENT_METHODS.STORE))) {
    return true;
  }
  return false;
};

export const isTwoTileOff = () => {
  let _isTwoTileOff = false;
  try {
    if (typeof window !== 'undefined' && window.location) {
      const queryParams = getQueryParams(window.location.search);
      _isTwoTileOff = !!queryParams.twoTileOff;
    }
  } catch (error) {
    // console.error(error);
  }
  return _isTwoTileOff;
};

// Get highest inventory (qty) value between STH & BODFS to show to customer
export const getMaxDeliverableQty = ({ shippingLocation, bodfsLocation }) => {
  const sthQty = shippingLocation?.inventory?.quantity;
  const bodfsQty = bodfsLocation?.inventory?.quantity;

  if (sthQty && bodfsQty) {
    if (sthQty >= bodfsQty) { return sthQty; }
    return bodfsQty;
  }
  if (sthQty) { return sthQty; }
  if (bodfsQty) { return bodfsQty; }

  return null;
};

export const doesCustomerQtyExceedInventory = (maxInventoryAvail = 0, quantity = 0) => {
  return (maxInventoryAvail > 0 && maxInventoryAvail < quantity);
};

export const isSubscriptionEnabled = ({ subscriptionEligible, quantity, sthQty }) => {
  return subscriptionEligible && (quantity <= sthQty);
};

export const getDeliveryFfmTypeToUse = ({
  quantity = 1,
  preferredFfmFromAPI = '',
  shippingLocation = {},
  bodfsLocation = {},
  currentDeliveryFfm = '',
  subscriptionEligible = false,
  pricing = {},
  isBackordered = false
}) => {
  const sthQty = shippingLocation?.inventory?.quantity || 0;
  const bodfsQty = bodfsLocation?.inventory?.quantity || 0;

  if (isSubscriptionEnabled({ subscriptionEligible, quantity, sthQty })) {
    return FULFILLMENT_METHODS.STH;
  }

  if (preferredFfmFromAPI === FULFILLMENT_METHODS.STH_FFM && (isBackordered || quantity <= sthQty)) {
    return FULFILLMENT_METHODS.STH;
  }

  if (preferredFfmFromAPI === FULFILLMENT_METHODS.EXPRESS_DELIVERY && quantity <= bodfsQty) {
    return FULFILLMENT_METHODS.BODFS;
  }

  // if qty exceeds all delivery inventory, show the current tile
  if (quantity > sthQty && quantity > bodfsQty) {
    if (currentDeliveryFfm === FULFILLMENT_METHODS.BODFS) { return FULFILLMENT_METHODS.BODFS; }
    return FULFILLMENT_METHODS.STH;
  }

  // if not enough STH, but we have enough BODFS, show BODFS.
  if (quantity > sthQty && quantity <= bodfsQty) { return FULFILLMENT_METHODS.BODFS; }

  if (currentDeliveryFfm === FULFILLMENT_METHODS.BODFS) {
    // retain current ffm if no other case applies
    return FULFILLMENT_METHODS.BODFS;
  }

  return FULFILLMENT_METHODS.STH; // default delivery ffm is STH
};

export const isShowBoth = () => {
  let _isShowBoth = false;
  try {
    if (typeof window !== 'undefined' && window.location) {
      const queryParams = getQueryParams(window.location.search);
      _isShowBoth = !!queryParams.showBoth;
    }
  } catch (error) {
    // console.error(error);
  }
  return _isShowBoth;
};

export const isMockOptimize = () => {
  let _mockOpt = false;
  try {
    if (typeof window !== 'undefined' && window.location) {
      const queryParams = getQueryParams(window.location.search);
      _mockOpt = !!queryParams.preferBodfs;
    }
  } catch (error) {
    // console.error(error);
  }
  return _mockOpt;
};

export const shippingDataHasFfmType = (shippingData, ffm) => {
  if (!shippingData || !ffm) { return false; }
  return !!((shippingData?.services || []).find((service) => service.type === ffm));
};

export const getSTHServiceLevelCount = (shippingData) => {
  return (shippingData?.services || []).filter((service) => service.type === 'sth').length || 0;
};

export const getDeliveryOptionsText = (shippingData) => {
  if (!shippingData) { return ''; }

  const sthServiceLevelCount = getSTHServiceLevelCount(shippingData);
  const isBodfsAvailable = shippingDataHasFfmType(shippingData, 'express delivery');
  const isSthAvailable = sthServiceLevelCount > 0;
  const isSthItemBigBulky = sthServiceLevelCount > 1; // if item has 2+ STH lvls, likely big and bulky

  // display the message only if there is multiple service level
  if (isSthItemBigBulky) {
    return MORE_DELIV_OPTIONS_TEXT;
  }

  // display the message if there is multiple fulfillment
  if (isBodfsAvailable && isSthAvailable) {
    return MORE_DELIV_OPTIONS_TEXT;
  }

  return '';
};

export const getPreferredFfmFromAPI = (fulfillment, preferSecondaryFFM = false) => {
  const deliveryService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const expressFulfillment = (deliveryService?.services || []).find((service) => service.type === 'express delivery');

  if (!shippingFulfillment?.optimalFulfillment && !expressFulfillment?.optimalFulfillment) {
    return '';
  }

  // get the optimal and secondary fulfillment, if bodfs assembly eligible, set bodfs as the prferred
  const preferredFfm = shippingFulfillment?.optimalFulfillment ? shippingFulfillment : expressFulfillment;
  const secondaryFfm = shippingFulfillment?.optimalFulfillment ? expressFulfillment : shippingFulfillment;

  // return secondary fulfillment if preferSecondaryFFM is true
  return preferSecondaryFFM ? secondaryFfm?.type : preferredFfm?.type;
};

export const isFunction = (value) => typeof value === 'function';

export const isApplianceDeliveryAvailable = (applianceDeliveryData) => {
  return (applianceDeliveryData?.availability?.[0]?.status === MAJOR_APPLIANCE_DELIVERY.AVAILABLE);
};

export const isApplianceDeliveryBackordered = (applianceDeliveryData) => {
  return (applianceDeliveryData?.availability?.[0]?.status === MAJOR_APPLIANCE_DELIVERY.BACK_ORDERED);
};

export const isApplianceDeliveryOutOfStock = (applianceDeliveryData) => {
  return !!(applianceDeliveryData?.availability?.[0]?.status === MAJOR_APPLIANCE_DELIVERY.OOS_ETA_UNAVAILABLE
    || applianceDeliveryData?.availability?.[0]?.status === MAJOR_APPLIANCE_DELIVERY.OOS_ALT_MODEL);
};

export const isApplianceDeliveryUnavailable = (applianceDeliveryData) => {
  return (applianceDeliveryData?.availability?.[0]?.status === MAJOR_APPLIANCE_DELIVERY.UNAVAILABLE);
};

export const isApplianceDeliveryError = (applianceDeliveryData) => {
  return !!(applianceDeliveryData?.errors);
};

export const getUrlForApplianceData = (itemId, itemIds, zipCode, urlOrigin) => {
  const idOrIds = itemId || (Array.isArray(itemIds) && !!itemIds.length ? itemIds.join() : '');
  if (!idOrIds || !zipCode) return null;

  let applianceDataUrl;
  if (typeof urlOrigin === 'string' && (urlOrigin.includes('hd-qa74') || urlOrigin.includes('hd-qa71'))) {
    applianceDataUrl = urlOrigin + APPLIANCE_URL_PATH;
  } else {
    applianceDataUrl = DEFAULT_PRODUCTION_URL_ORIGIN + APPLIANCE_URL_PATH;
  }

  applianceDataUrl = applianceDataUrl.replace('{idOrIds}', idOrIds);
  applianceDataUrl = applianceDataUrl.replace('{zipCode}', zipCode);

  return applianceDataUrl;
};

export const isExchangeCustomer = () => {
  if (window.THD_GLOBAL && window.THD_GLOBAL.AAFES) {
    return window.THD_GLOBAL.AAFES.IS_EXCHANGE_CUSTOMER && window.THD_GLOBAL.EXCHANGE.IS_EXCHANGE_CUSTOMER !== 'false';
  } if (window.THD_GLOBAL && window.THD_GLOBAL.EXCHANGE) {
    return window.THD_GLOBAL.EXCHANGE.IS_EXCHANGE_CUSTOMER
      && window.THD_GLOBAL.EXCHANGE.IS_EXCHANGE_CUSTOMER !== 'false';
  }
  return false;
};
