import { useDataModel } from '@thd-nucleus/data-sources';
import { useContext, useRef } from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';
import { getParams } from './productShelfHookHelpers';

export const useDpdSearchModel = (props) => {
  // const { channel } = useContext(ExperienceContext);
  const { storeId, storeZip } = useStore();
  const params = getParams(props);

  const usableStoreId = params.storeId || props.storeId || storeId;
  const usableStoreZip = params.storeZip || params.zipCode || props.storeZip || props.zipCode || storeZip;
  const skuRef = useRef([]);
  const storedMetadata = useRef({});
  const hookOpts = {
    channel: params.channel?.toUpperCase(),
    navParam: params.navParam || '',
    keyword: params.keyword || '',
    orderBy: params.orderBy || {},
    itemIds: params.itemIds,
    pageSize: 24,
    startIndex: 0,
    storeId: usableStoreId,
    zipCode: usableStoreZip
  };


  const loadedStoreId = useRef(usableStoreId);
  const loadedStoreZip = useRef(usableStoreZip);

  const { data, loading, error, variables } = useDataModel('dpdSearchModel', {
    variables: {
      ...hookOpts,
      additionalSearchParams: {
        callback: JSON.stringify(hookOpts.orderBy)
      }
    },
    skip: (!hookOpts.keyword && !hookOpts.navParam && !hookOpts.itemIds) || hookOpts.startIndex >= 24,
    ssr: true
  });

  if (loading) {
    return {
      loading, storeId: loadedStoreId.current, storeZip: loadedStoreZip.current, skus: skuRef.current, ...storedMetadata.current
    };
  }

  if (error) {
    console.log(error);
  }

  if (variables?.storeId) {
    loadedStoreId.current = variables.storeId;
  }
  if (variables?.zipCode || variables?.storeZip) {
    loadedStoreZip.current = variables?.zipCode || variables.storeZip;
  }

  // logic parsing response here:
  let skus = data?.searchModel?.products || [];
  let categoryLabel = '';
  let shopAllLink = '';
  let totalProducts = Math.min(data?.searchModel?.searchReport?.totalProducts || 0, 24);

  if (skus && skus.length > 0) {
    const { metadata = {}, searchReport = {} } = data?.searchModel;
    const sortByResponseParam = searchReport?.sortBy;
    const { canonicalUrl } = (hookOpts.navParam && metadata) || {};
    shopAllLink = canonicalUrl
      ? `${canonicalUrl}?sortby=${sortByResponseParam}&catStyle=ShowProducts`
      : '';
    const contentfulTitle = props?.opts?.contentfulTitle;
    if ( contentfulTitle ) {
      categoryLabel = contentfulTitle;
    }
    skus = skus.filter((sku) => {
      if (sku.fulfillment) {
        return ProductPodUtils.isATCEnabled(sku);
      }
      return true;
    });
  }

  if (hookOpts.itemIds) {
    totalProducts = skus.length;
    skuRef.current = [...skus];
  } else {
    skuRef.current.splice(hookOpts.startIndex, hookOpts.pageSize, ...skus);
  }

  storedMetadata.current = {
    shopAllLink,
    categoryLabel,
    totalProducts
  };

  return {
    loading,
    skus: skuRef.current,
    shopAllLink,
    categoryLabel,
    storeId: loadedStoreId.current,
    storeZip: loadedStoreZip.current,
    totalProducts
  };
};