import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PositionedDrawer = React.forwardRef((props, ref) => {

  const { position, children, ...other } = props;

  const paperCls = 'sui-flex sui-flex-col sui-rounded-base sui-bg-primary sui-shadow-lg sui-overflow-y-auto sui-z-max sui-outline-none sui-max-w-full sui-h-full md:sui-w-auto';

  const gridCls = classNames('sui-row-start-1 sui-row-span-full sui-col-span-9 md:sui-col-span-8 lg:sui-col-span-6 xl:sui-col-span-5 2xl:sui-col-span-4', {
    'sui-col-start-4 md:sui-col-start-5 lg:sui-col-start-7 xl:sui-col-start-8 2xl:sui-col-start-9': position === 'right',
    'sui-col-start-1': position === 'left'
  });

  return (
    <div
      ref={ref}
      className={`${paperCls} ${gridCls}`}
      {...other}
    >
      {children}
    </div>
  );
});

PositionedDrawer.displayName = 'PositionedDrawer';

PositionedDrawer.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string
};

PositionedDrawer.defaultProps = {
};

export { PositionedDrawer };