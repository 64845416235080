import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';
import RevJetScript from './RevJetScript';

const RevjetBundle = ({
  tag,
  plcId,
  keyId,
  omsid,
  recommendationId,
  deliveryWrapper,
  autohide,
  responsive,
  responsiveHeight,
  autoscale,
  deliveryMethod,
  impBannerSize,
  deliveryFormat,
  thdPageId,
  previewApDate,
  previewKeyId,
  previewTag,
  isPreview,
  innervateCarouselIndex,
  customerType,
  carouselPosition
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('revjet_bundle.ready'); }, []);

  const tagProps = {
    _tag: isPreview && previewTag ? previewTag : tag,
    _key: isPreview && previewKeyId ? previewKeyId : keyId,
    _plc_id: plcId || '',
    omsid: omsid || '',
    _thd_pageid: thdPageId || '',
    __recommendation_id: recommendationId || '',
    _thd_carousel_index: innervateCarouselIndex,
    _thd_customer_type: customerType,
    _thd_carousel_position: carouselPosition,
    _opts: {
      deliveryWrapper: deliveryWrapper || '',
      autohide,
      responsive,
      responsive_height: responsiveHeight || '',
      autoscale,
      delivery_method: deliveryMethod || '',
      _imp_banner_size: impBannerSize || '',
      delivery_format: deliveryFormat || '',
      _ap_date: isPreview && previewApDate ? previewApDate : ''
      // TODO: Innervate devs said that this field belongs on the root instead of opts
      // does that change anything?
    }
  };

  return (
    <div className="revjet_bundle" data-component="RevJetBundle">
      <RevJetScript tagProperties={tagProps} />
    </div>
  );
};

// TODO: alphabetize? it's a lot of props to look at.
RevjetBundle.propTypes = {
  tag: string.isRequired,
  plcId: string,
  keyId: string.isRequired,
  omsid: string,
  deliveryWrapper: string,
  autohide: bool,
  responsive: bool,
  responsiveHeight: string,
  autoscale: bool,
  deliveryMethod: string,
  impBannerSize: string,
  deliveryFormat: string,
  recommendationId: string,
  thdPageId: string,
  previewApDate: string,
  previewKeyId: string,
  previewTag: string,
  isPreview: bool,
  innervateCarouselIndex: string,
  customerType: string,
  carouselPosition: string
};

RevjetBundle.defaultProps = {
  plcId: '',
  omsid: '',
  deliveryWrapper: '',
  autohide: true,
  responsive: true,
  responsiveHeight: '',
  autoscale: true,
  deliveryMethod: '',
  impBannerSize: '',
  recommendationId: '',
  deliveryFormat: '',
  thdPageId: '',
  previewApDate: 'defaultProp',
  previewKeyId: 'defaultProp',
  previewTag: 'defaultProp',
  isPreview: false,
  innervateCarouselIndex: '',
  customerType: '',
  carouselPosition: ''
};

RevjetBundle.displayName = 'RevjetBundle';

export { RevjetBundle };
