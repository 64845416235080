import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf, shape, number, client, bool, useDataModel,
} from '@thd-nucleus/data-sources';
import classNames from 'classnames';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { Button } from '@thd-olt-component-react/core-ui';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { useImpression } from '@thd-olt-component-react/impression';
import { ScheduleAMeasureCta, GccCarpetSamCta } from '@thd-olt-component-react/home-services-cta-buttons';
import { ServicesButton } from './ServicesButton';
import {
  ADD_TO_CART,
  PRODUCT_POD_IRG_ATC,
  PRODUCT_POD_ATC,
  PRODUCT_POD_V7_CLICK,
  SCHEDULE_A_MEASURE,
  GET_ESTIMATE,
  REQUEST_A_QUOTE,
  SCHEDULE_A_CONSULTATION,
  BUILD_AND_BUY,
  VIEW_DETAILS,
  CHOOSE_YOUR_OPTIONS,
  NON_RETURNABLE_MESSAGE,
  CLOPAY_URL
} from '../../constants';

import {
  isBuildAndBuyProduct,
  getCustomUrlWithAnalytics,
  isCustomKitchenCabinetProduct,
  isLiveGoodsOOSProduct,
  isBrioProduct,
  isHDDCSku
} from '../../productPodUtils';

import {
  getMisship,
  getShowBopisOverlay,
  isAppliance,
  getCartOptions,
  getCartReqParams
} from './product-atc-utils';
import styles from './product-atc.module.scss';

const {
  isATCEnabled,
  isFulfillable,
  isProductOutOfStockOnline
} = podFulFillmentUtils;

const cx = classNames.bind(styles);

const PlaceholderATC = () => {
  return (
    <AddToCart
      loading
    >
      Add to Cart
    </AddToCart>
  );
};

export const ProductATC = (props) => {
  const {
    className,
    checkGeneric,
    disabled,
    doNotShowChooseYourOptions,
    dpdNewTab,
    hasInStoreFilter,
    hideATC,
    itemId,
    noATCFulfillment,
    onCartFailure,
    onCartSuccess,
    onClick,
    sharedSection,
    showDisabledATC,
    showReturnable,
    showSoldOutMessage,
    silent,
    sponsoredValues,
    staticQuantity,
    subscriptionInfo,
    target,
    quantity: editableQuantity,
    storeId,
    variant
  } = props;

  const {
    channel,
    hosts,
    dataType
  } = useContext(ExperienceContext);

  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductATC',
      name: 'ProductATC',
      position: 0
    }
  });

  const { parent = '', section, position } = additionalData;

  const store = useStore();

  let deliveryZipCode;
  if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails) {
    deliveryZipCode = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails()?.zipcode;
  }

  const { data, error, loading } = useDataModel('product', {
    variables: {
      itemId,
      storeId: storeId || store.storeId
    }
  });

  if (hideATC) return null;

  const quantity = staticQuantity || editableQuantity;
  const product = data?.product;

  if (loading || (
    typeof (data) === 'undefined' && !error
  )) return <PlaceholderATC />;

  if (!product || product?.info?.productSubType?.name === 'HDQC') return null;

  const {
    scheduleAMeasure = false,
    gccCarpetDesignAndOrderEligible = false,
  } = product?.installServices || {};

  const isRequestAQuoteEligible = isBrioProduct(product);
  const isScheduleAConsultationEligible = isCustomKitchenCabinetProduct(product);

  const isDisabled = (!isAppliance(product) && !isATCEnabled(product)) || disabled;
  const showViewDetailsBtn = isLiveGoodsOOSProduct(product) || isHDDCSku(product);
  const showBuildAndBuyButton = isBuildAndBuyProduct(product);
  const showRequestAQuoteButton = isRequestAQuoteEligible;
  const showScheduleAConsultationButton = isScheduleAConsultationEligible;
  const showChooseYourOptionsButton = !doNotShowChooseYourOptions && checkGeneric && product?.info?.isGenericProduct;

  const isClopayAndBrioSku = data?.product?.identifiers?.brandName === 'Clopay'
    && data?.product?.info?.isBrioSku;
  const showClopayRequestAQuoteButton = showRequestAQuoteButton && isClopayAndBrioSku;

  const { info, identifiers } = product || {};
  const { isBuryProduct = false } = info || {};

  const showSimilarButton = isBuryProduct && !isFulfillable(product);

  const shouldHide = !scheduleAMeasure
    && !showDisabledATC
    && isDisabled
    && !showBuildAndBuyButton
    && !showViewDetailsBtn
    && !showRequestAQuoteButton
    && !showScheduleAConsultationButton
    && showSimilarButton;

  if (shouldHide) return null;

  let cartOptionsSharedSection = sharedSection;

  const returnMessage = showReturnable
    && (/non-returnable/i.test(product?.info?.returnable)) ? NON_RETURNABLE_MESSAGE : null;

  const cartOptions = getCartOptions({
    channel,
    hidden: !!silent,
    sharedSection: cartOptionsSharedSection,
    misship: getMisship({ product })
  });
  const cartReqParams = getCartReqParams({
    store, quantity, deliveryZipCode, product, channel, noATCFulfillment, hasInStoreFilter, subscriptionInfo
  });
  const showBopisOverlay = getShowBopisOverlay({ product, cartReqParams });
  const isMobile = channel === 'mobile';
  const { canonicalUrl, productType = '' } = identifiers || {};

  const getAtcLabel = () => {
    if (info?.customerSignal?.previouslyPurchased) return 'Buy It Again';
    if (isProductOutOfStockOnline(product) && !isAppliance(product) && !showSoldOutMessage) return 'Out Of Stock';
    if (isDisabled && showSoldOutMessage) return 'Sold Out';
    if (subscriptionInfo?.isOptIn) return 'Subscribe';
    return 'Add to Cart';
  };

  const atcLabel = getAtcLabel();
  const productUrl = getCustomUrlWithAnalytics({
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    info,
    sponsoredValues
  });

  const servicesURL = info?.productSubType?.link;

  const cartClicked = (event) => {
    const action = ADD_TO_CART;

    let eventName = PRODUCT_POD_ATC;

    const isTableView = dataType === 'expanded-product-data';

    let eventData = {
      podAction: action,
      podAnchorSku: itemId,
      isTableView,
      target,
      parent: parent.toLowerCase(),
      section
    };

    if (parent === 'IRG') {
      eventName = PRODUCT_POD_IRG_ATC;

      eventData = {
        sku: itemId,
        displayPosition: position,
        irgSectionName: section
      };
    }

    if (parent !== 'DPD' && parent !== 'PIP') {
      LIFE_CYCLE_EVENT_BUS.trigger(eventName, eventData);

      if (parent === 'plp') {
        LIFE_CYCLE_EVENT_BUS.trigger(PRODUCT_POD_V7_CLICK, eventData);
      }
    }

    if (onClick) {
      onClick(event, product, action);
    }
  };

  const clickEventData = {
    podAnchorSku: itemId,
    target,
    parent
  };

  const triggerAnalyticsEvent = (action) => {
    LIFE_CYCLE_EVENT_BUS.trigger(PRODUCT_POD_V7_CLICK, {
      podAction: action, ...clickEventData
    });
  };

  const onServicesButtonClick = (buttonName) => {
    triggerAnalyticsEvent(buttonName.toLowerCase());

    const urlParts = servicesURL?.split('/form')?.[0]?.split('/');
    const formName = urlParts?.[urlParts?.length - 1];

    const eventData = {
      eventName: buttonName.toLowerCase(),
      formName: formName.toLowerCase()
    };

    LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.services-button.click', eventData);
  };

  if (scheduleAMeasure && gccCarpetDesignAndOrderEligible) {
    return (
      <GccCarpetSamCta
        itemId={itemId}
        onClick={() => triggerAnalyticsEvent(GET_ESTIMATE.toLowerCase())}
        hideLabel
        target="_self"
      />
    );
  }

  if (scheduleAMeasure) {
    return (
      <ScheduleAMeasureCta
        itemId={itemId}
        hideContactInfo
        onClick={() => triggerAnalyticsEvent(SCHEDULE_A_MEASURE.toLowerCase())}
      />
    );
  }

  if (showBuildAndBuyButton) {
    return (
      <Button
        href={productUrl}
        target={target}
        onClick={() => triggerAnalyticsEvent(BUILD_AND_BUY)}
        tag="a"
        outline
      >
        {BUILD_AND_BUY}
      </Button>
    );
  }

  if (showViewDetailsBtn) {
    return (
      <Button
        dark
        href={productUrl}
        outline
        small={isMobile}
        tag="a"
        target={target}
        data-testid="bttn__view-details"
      >
        {VIEW_DETAILS}
      </Button>
    );
  }

  if (showRequestAQuoteButton) {
    return showClopayRequestAQuoteButton
      ? (
        <Button
          dark
          data-testid="bttn__request-a-quote"
          href={CLOPAY_URL}
          onClick={() => onServicesButtonClick(REQUEST_A_QUOTE)}
          outline
          small={isMobile}
          tag="a"
          target="_blank"
        >
          {REQUEST_A_QUOTE}
        </Button>
      )
      : (
        <ServicesButton
          name={REQUEST_A_QUOTE}
          servicesURL={servicesURL}
          onClick={() => onServicesButtonClick(REQUEST_A_QUOTE)}
        />
      );
  }

  if (showScheduleAConsultationButton) {
    return (
      <ServicesButton
        name={SCHEDULE_A_CONSULTATION}
        servicesURL={servicesURL}
        onClick={() => onServicesButtonClick(SCHEDULE_A_CONSULTATION)}
      />
    );
  }

  if (showChooseYourOptionsButton) {
    const newTab = dpdNewTab && !target ? '_blank' : target;
    return (
      <Button
        dark
        href={productUrl}
        onClick={(event) => onClick(event, product)}
        outline
        small={isMobile}
        tag="a"
        target={newTab}
      >
        {CHOOSE_YOUR_OPTIONS}
      </Button>
    );
  }

  return ((
    <>
      <AddToCart
        className={className}
        checkGeneric={checkGeneric}
        showDisabledATC={showDisabledATC}
        showReturnable={showReturnable}
        cartOptions={cartOptions}
        cartReqParams={cartReqParams}
        disabled={isDisabled}
        onClick={cartClicked}
        variant={variant}
        showBOPISOverlay={showBopisOverlay}
        onSuccess={onCartSuccess}
        onFail={onCartFailure}
      >
        {atcLabel}
      </AddToCart>
      {returnMessage && (
        <div className={cx('product-atc__returns-messaging')}>
          {returnMessage}
        </div>
      )}
    </>
  ));
};

ProductATC.propTypes = {
  /** When true, checks if sku is generic and returns "Choose Your Options" button */
  checkGeneric: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  /** Method triggered when `checkGeneric` is true and "Choose Your Options" button clicked */
  onClick: PropTypes.func,
  /** show return messaging */
  showReturnable: PropTypes.bool,
  /** Add to Cart without overlay */
  silent: PropTypes.bool,
  subscriptionInfo: PropTypes.shape({
    frequency: PropTypes.string,
    isOptIn: PropTypes.bool
  }),
  doNotShowChooseYourOptions: PropTypes.bool,
  dpdNewTab: PropTypes.bool,
  hasInStoreFilter: PropTypes.bool,
  itemId: PropTypes.string,
  noATCFulfillment: PropTypes.bool,
  onCartSuccess: PropTypes.func,
  onCartFailure: PropTypes.func,
  showDisabledATC: PropTypes.bool,
  hideATC: PropTypes.bool,
  sharedSection: PropTypes.string,
  showSoldOutMessage: PropTypes.bool,
  staticQuantity: PropTypes.number,
  sponsoredValues: PropTypes.shape({}),
  target: PropTypes.string,
  quantity: PropTypes.number,
  storeId: PropTypes.string,
  variant: PropTypes.string
};

ProductATC.defaultProps = {
  checkGeneric: true,
  className: null,
  disabled: false,
  onClick: () => { },
  showReturnable: false,
  silent: false,
  subscriptionInfo: {},
  doNotShowChooseYourOptions: false,
  dpdNewTab: false,
  hasInStoreFilter: false,
  itemId: '',
  noATCFulfillment: false,
  onCartSuccess: () => { },
  onCartFailure: () => { },
  showDisabledATC: false,
  hideATC: false,
  sharedSection: '',
  showSoldOutMessage: false,
  staticQuantity: 0,
  sponsoredValues: null,
  target: '',
  quantity: 1,
  storeId: null,
  variant: 'secondary'
};

ProductATC.displayName = 'ProductATC';

const Service = shape({
  deliveryTimeline: string(),
  deliveryDates: shape({
    startDate: string(),
    endDate: string(),
  }),
  deliveryCharge: string(),
  dynamicEta: shape({
    hours: string(),
    minutes: string()
  }),
  hasFreeShipping: bool(),
  freeDeliveryThreshold: number({ float: true }),
  locations: arrayOf(shape({
    curbsidePickupFlag: bool(),
    isBuyInStoreCheckNearBy: bool(),
    distance: number({ float: true }),
    inventory: shape({
      isOutOfStock: bool(),
      isInStock: bool(),
      isLimitedQuantity: bool(),
      isUnavailable: bool(),
      quantity: number(),
      maxAllowedBopisQty: number(),
      minAllowedBopisQty: number()
    }),
    isAnchor: bool(),
    locationId: string(),
    state: string(),
    storeName: string(),
    storePhone: string(),
    type: string(),
  })),
  type: string(),
  totalCharge: number({ float: true }),
});

const installServices = params({ storeId: string(), zipCode: string() }).shape({
  scheduleAMeasure: bool(),
  gccCarpetDesignAndOrderEligible: bool()
});
if (installServices.skip) {
  installServices.skip('skipInstallServices', true);
}

ProductATC.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    details: shape({
      installation: shape({
        serviceType: string()
      })
    }),
    fulfillment: client(params({ storeId: string(), zipCode: string() }).shape({
      anchorStoreStatus: bool(),
      anchorStoreStatusType: string(),
      backordered: bool(),
      backorderedShipDate: string(),
      bossExcludedShipStates: string(),
      excludedShipStates: string(),
      seasonStatusEligible: bool(),
      fulfillmentOptions: arrayOf(shape({
        type: string(),
        fulfillable: bool(),
        services: arrayOf(Service)
      })),
      onlineStoreStatus: bool(),
      onlineStoreStatusType: string(),
    })),
    identifiers: shape({
      brandName: string(),
      productType: string(),
      canonicalUrl: string(),
      specialOrderSku: string(),
      storeSkuNumber: string()
    }),
    info: shape({
      customerSignal: shape({
        previouslyPurchased: bool(),
      }),
      isBuryProduct: bool(),
      isGenericProduct: bool(),
      isBrioSku: bool().client(),
      isCustomKitchenCabinet: bool().client(),
      productSubType: shape({
        name: string(),
        link: string(),
      }),
      returnable: string(),
    }),
    installServices
  }),
};
