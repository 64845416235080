import React, {
  useEffect,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  string, number, bool, shape, array, arrayOf
} from 'prop-types';
import {
  ExperienceContext,
  useStore,
  useConfigService
} from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { Carousel } from '@one-thd/sui-carousel';
import { Card, CardTitle, Typography } from '@one-thd/sui-atomic-components';
import { ProductPodUtils } from '@thd-olt-component-react/product-pod';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import {
  useDataModel,
  customType,
  extend,
  params,
  string as dsString,
  bool as dsBool,
  number as dsNumber,
  shape as dsShape,
  arrayOf as dsArrayOf,
  QueryProvider,
  QueryContext,
} from '@thd-nucleus/data-sources';
import { SponsoredProductPod } from '../SponsoredProductPod';
import { getSchema, isExperienceDataResolved, showCarouselPlaceholder, nValueIsValid } from '../../utils/helpers';
import './SponsoredCarousel.scss';
import { useNodeRef, useSponsoredCarouselTelemetry } from '../../utils/hooks';

const propTypes = {
  breadCrumbs: arrayOf(shape({
    browseUrl: string,
    label: string,
  })),
  browserId: string,
  userId: string,
  categorySourceId: string,
  dynamic: shape({
    pageType: string,
  }),
  pageContext: shape({
    label: string,
    data: shape({
      searchModel: shape({
        appliedDimensions: array,
      }),
      itemId: string,
      itemIds: arrayOf(string),
    }),
    keyword: string,
    isSearch: bool,
    isCategory: bool,
    isBrowse: bool,
    isPip: bool,
    isDiscoveryZone: bool,
    schema: string,
    slugId: string
  }),
  slidesPer: number,
  nValue: string,
  noATCFulfillment: bool,
  utilizeRvData: bool,
  mockEnabledFeatureSwitch: bool,
  showSponsoredCarousel: bool,
  hideCarouselArrows: bool,
  dataSource: string,
  enableForChapters: bool,
  requestedAdCount: number,
  schema: string,
  plaLocation: string,
  useSvocIdAsCustomerId: bool,
};

const defaultProps = {
  breadCrumbs: null,
  browserId: '',
  userId: '',
  categorySourceId: null,
  pageContext: {},
  dynamic: {},
  slidesPer: null,
  noATCFulfillment: null,
  nValue: null,
  utilizeRvData: false,
  mockEnabledFeatureSwitch: false,
  showSponsoredCarousel: false,
  hideCarouselArrows: false,
  dataSource: 'pla',
  requestedAdCount: 25,
  enableForChapters: false,
  schema: undefined,
  plaLocation: '',
  useSvocIdAsCustomerId: false,
};

const SponsoredCarouselComponent = (props) => {
  const {
    breadCrumbs,
    browserId,
    userId,
    categorySourceId,
    pageContext,
    slidesPer,
    noATCFulfillment,
    nValue,
    mockEnabledFeatureSwitch,
    showSponsoredCarousel,
    hideCarouselArrows,
    dataSource,
    enableForChapters,
    utilizeRvData,
    requestedAdCount,
    dynamic,
    useSvocIdAsCustomerId,
  } = props;

  const experienceContext = useContext(ExperienceContext);
  const isMobile = experienceContext?.channel === 'mobile';
  const thdCustomer = useThdCustomer();
  const renderNewCarouselPip = useConfigService('fs-prop:pip-master__sponsored-carousel-nucleus--renderNewCarousel');
  const carouselType = useConfigService('fs-prop:tnt-carouselType');
  const renderNewCarouselBrowseAndSearch = useConfigService('fs-prop:browse-and-search-master__sponsored-carousel-nucleus--renderNewCarousel');
  const { storeId, isLocalized, storeZip } = useStore({ varnish: true, online: true });
  const targetingSource = 'plaModel';

  // node
  const [carouselNodeRef, carouselNode] = useNodeRef();

  // --
  const breakpoints = useMemo(() => {
    if (!slidesPer) {
      return Carousel.defaultProps.breakpoints;
    }
    return {
      sm: { slidesPerView: slidesPer, slidesPerGroup: slidesPer },
      md: { slidesPerView: slidesPer, slidesPerGroup: slidesPer },
      lg: { slidesPerView: slidesPer, slidesPerGroup: slidesPer },
      xl: { slidesPerView: slidesPer, slidesPerGroup: slidesPer },
    };

  }, [slidesPer]);

  /**
   * MCMID
   */
  const [mcmid, setMcmid] = useState(experienceContext?.cookie?.adobeCookie?.MCMID);

  // updater
  useEffect(() => {
    setMcmid((cur) => {
      // if the new incoming value is not set, then keep the current value.
      if (!experienceContext?.cookie?.adobeCookie?.MCMID) return cur;
      return experienceContext?.cookie?.adobeCookie?.MCMID;
    });
  }, [experienceContext?.cookie?.adobeCookie?.MCMID]);
  // --

  // --
  const validBrowserId = useMemo(() => {
    return browserId || thdCustomer?.mcvisID || mcmid || '';
  }, [browserId, mcmid, thdCustomer?.mcvisID]);

  // --
  const customerUserId = useMemo(() => {
    if (useSvocIdAsCustomerId) return userId || thdCustomer?.svocID || thdCustomer?.userID || thdCustomer?.guestUserID || null;
    return userId || thdCustomer?.userID || thdCustomer?.guestUserID || null;
  }, [userId, thdCustomer?.svocId, thdCustomer?.userID, thdCustomer?.guestUserID, useSvocIdAsCustomerId]);

  // --
  const customerSvocId = useMemo(() => {
    return thdCustomer?.svocID || null;
  }, [thdCustomer?.svocID]);

  // --
  const schema = useMemo(() => {
    return getSchema({
      props: { schema: props?.schema },
      schema: pageContext?.schema,
      pageContext: {
        label: pageContext?.label,
        isCategory: pageContext?.isCategory,
        isSearch: pageContext?.isSearch,
        isBrowse: pageContext?.isBrowse,
        data: { searchModel: { appliedDimensions: pageContext?.data?.searchModel?.appliedDimensions } },
      },
    }) || 'pip_sponsored';
  }, [
    props?.schema,
    pageContext?.schema,
    pageContext?.label,
    pageContext?.isCategory,
    pageContext?.isSearch,
    pageContext?.isBrowse,
    pageContext?.data?.searchModel?.appliedDimensions,
  ]);

  // --
  const plaLocation = useMemo(() => {
    if (props?.plaLocation) return props.plaLocation;

    // --
    if (pageContext?.label === 'browse-search') {
      if (pageContext?.isCategory) return 'sponsoredCarouselCategoryPage';
      if (pageContext?.isSearch) return 'sponsoredCarouselSearchProductListingPage';
      if (pageContext?.isBrowse) return 'sponsoredCarouselBrowseProductListingPage';
    }

    // --
    if (pageContext?.isPip) return 'sponsoredCarouselProductInformationPage';

    // --
    if (pageContext?.isDiscoveryZone) return 'sponsoredCarouselBrowsePageDiscoveryZone';

    return null;
  }, [
    props?.plaLocation,
    pageContext?.label,
    pageContext?.isCategory,
    pageContext?.isSearch,
    pageContext?.isBrowse,
    pageContext?.isPip,
    pageContext?.isDiscoveryZone,
  ]);

  // --
  const renderNewCarousel = useMemo(() => {
    return (
      enableForChapters
      || renderNewCarouselPip
      || renderNewCarouselBrowseAndSearch
      || showSponsoredCarousel
      || mockEnabledFeatureSwitch
      || schema === 'browse_sponsored'
      || schema === 'cat_sponsored'
      || schema === 'cart_sponsored'
      || schema === 'event_browse_sponsored'
      || schema === 'hp_sponsored_auth'
      || schema === 'hp_sponsored'
      || schema === 'pip_sponsored'
      || schema === 'search_sponsored'
      || schema === 'storepage_sponsored'
      || schema === 'thankyoupage_sponsored'
      || schema === 'no_results_found_sponsored'
    );
  }, [
    enableForChapters,
    renderNewCarouselPip,
    renderNewCarouselBrowseAndSearch,
    showSponsoredCarousel,
    mockEnabledFeatureSwitch,
    schema,
  ]);

  //-
  // Extracting the 'breadCrumbs' property and assigning it to the 'breadCrumbs' variable.
  const breadCrumbValue = breadCrumbs?.map(({ __typename, ...rest }) => {
    // Filtering out null values from the rest of the object properties.
    const filteredRest = Object.fromEntries(
      Object.entries(rest).filter(([key, value]) => value !== null)
    );
    // Returning a new object with non-null properties after filtering.
    return { ...filteredRest };
  }) || null;

  // --
  const zipCode = useMemo(() => {
    return experienceContext?.deliveryZip || storeZip || '30339';
  }, [experienceContext?.deliveryZip, storeZip]);

  const channel = useMemo(() => {
    return experienceContext?.isConsumerApp ? 'mobileconsumer' : experienceContext?.channel;
  }, [experienceContext?.isConsumerApp, experienceContext?.channel]);

  // --
  const isResolvedContext = useMemo(() => {
    return isExperienceDataResolved({ isLocalized, experienceContext });
  }, [isLocalized, experienceContext]);

  // --
  const isNValueValid = useMemo(() => {
    if (plaLocation === 'sponsoredCarouselLandingPage') {
      return nValueIsValid(nValue);
    } return true;
  }, [nValue, plaLocation]);

  // --
  const skip = useMemo(() => {
    return (!validBrowserId || !isResolvedContext || !isNValueValid);
  }, [validBrowserId, isResolvedContext, isNValueValid]);

  // --
  const itemIds = useMemo(() => {
    return pageContext?.data?.itemIds?.slice(0, 50) || null;
  }, [pageContext?.data?.itemIds]);

  /**
   * GraphQL query: plaModel
   */
  // ----
  const resp = useDataModel('plaModel', {
    skip,
    ssr: false,
    variables: {
      breadCrumbs: breadCrumbValue,
      categorySourceId,
      channel,
      customer: {
        mcVisId: validBrowserId,
        customerType: experienceContext?.customer?.type,
        userId: customerUserId,
        svocId: customerSvocId,
      },
      debug: false,
      itemId: pageContext?.data?.itemId,
      itemIds,
      keyword: pageContext?.keyword,
      nValue,
      requestedAdCount,
      schema,
      storeId,
      zipCode,
      plaLocation,
      ...(carouselType && { carouselType })
    },
  });
  const { data, loading, error } = resp;
  // ---

  /**
   * filteredProducts
   */
  // --- --- ---
  const filteredProducts = useMemo(() => {
    // check
    if (loading || error || !data) return null;
    if (!data.plaModel?.products?.length) return [];

    const products = data?.plaModel?.products;
    return products.filter((product) => ProductPodUtils.isATCEnabled(product));

  }, [data, error, loading]);
  // --- --- ---

  const carouselTitle = useMemo(() => {
    if (loading || error || !data) return null;
    const title = data?.plaModel?.carouselMetadata?.title || 'Sponsored Products';
    return title;
  }, [data, error, loading]);

  // -----------------
  // Telemetry
  // -----------------
  const { triggerSlideChange } = useSponsoredCarouselTelemetry({
    carouselNode,
    data,
    loading,
    error,
    filteredProducts,
    requestedAdCount,
    renderNewCarousel,
  });

  // -----------------
  // Handlers
  // -----------------
  /**
   * Handles 'slideChange' from Swiper.
   */
  const onSlideChange = useCallback((swiper) => {
    triggerSlideChange(swiper);
  }, [triggerSlideChange]);

  // -----------------
  // Placeholder
  // -----------------
  if (showCarouselPlaceholder(renderNewCarousel, experienceContext, pageContext, loading)) {
    let slides = [];
    let textPlaceholder = [...Array(16)].map((value, idx) => <Placeholder type="text" key={idx.toString()} />);
    for (let i = 0; i < 12; i += 1) {
      slides.push(
        <div key={i.toString()} style={{ width: '100%' }}>
          <Placeholder
            type="rect"
            height="150px"
            width="100%"
          />
          {textPlaceholder}
        </div>
      );
    }
    return (
      <div className="sui-w-full" data-component="SponsoredCarouselPlaceholder">
        <Carousel breakpoints={breakpoints} hideCarouselArrows={hideCarouselArrows} disableMargin>
          {slides}
        </Carousel>
      </div>
    );
  }

  // -----------------
  // Header
  // -----------------

  const HeaderComponent = () => {
    return (
      <div
        className="sponsored-nucleus-carousel__title"
        data-testid="sponsored-carousel-title"
      >
        <CardTitle
          header={(
            <Typography variant="h2">
              {pageContext?.isDiscoveryZone ? 'More To Explore' : carouselTitle}
            </Typography>
          )}
        />
        {pageContext?.isDiscoveryZone && <Typography color="subtle" className="sponsored-nucleus-carousel-sponsored-tag">Sponsored</Typography>}
      </div>
    );
  };

  // -----------------
  // Carousel
  // -----------------
  return (
    <>
      {(renderNewCarousel && filteredProducts?.length) ? (
        <div
          ref={carouselNodeRef}
          className="sui-w-full"
          id="sponsored-carousel-nucleus"
          data-component="SponsoredCarousel"
        >
          <div
            id={schema}
            className="sponsored-nucleus-carousel"
            data-testid="sponsored-pla-carousel"
          >
            <meta data-prop="name" content={schema} />
            <Card className="sui-h-full sui-leading-none noborder">
              <HeaderComponent />
              <QueryProvider
                cacheKey="sponsored-product-pod"
                dataSource={dataSource}
                defaultVariables={{
                  storeId,
                  zipCode,
                }}
              >
                <Carousel
                  breakpoints={breakpoints}
                  hideCarouselArrows={hideCarouselArrows}
                  disableMargin
                  {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
                  SwiperProps={{
                    onSlideChange,
                  }}
                >
                  {filteredProducts.map((product, idx) => {
                    return (
                      <SponsoredProductPod
                        key={product.itemId}
                        product={product}
                        itemId={product.itemId}
                        storeId={storeId}
                        position={idx + 1}
                        schema={schema}
                        noATCFulfillment={noATCFulfillment}
                        targetingSource={targetingSource}
                        showAtc={!pageContext?.isDiscoveryZone}
                      />
                    );
                  })}
                </Carousel>
              </QueryProvider>
            </Card>
          </div>
        </div>
      ) : null}
    </>
  );

};

SponsoredCarouselComponent.propTypes = propTypes;
SponsoredCarouselComponent.defaultProps = defaultProps;

SponsoredCarouselComponent.dataModel = extend({
  plaModel: params({
    breadCrumbs: dsArrayOf(customType('BreadCrumbInput').shape({
      browseUrl: dsString(),
      label: dsString()
    })),
    categorySourceId: dsString(),
    channel: dsString(),
    customer: customType('Customer').shape({
      mcVisId: dsNumber(),
      customerType: dsString(),
      userId: dsString(),
      svocId: dsString(),
    }),
    debug: dsBool(),
    itemId: dsString(),
    itemIds: dsArrayOf(dsString()),
    keyword: dsString(),
    nValue: dsString(),
    requestedAdCount: dsNumber(),
    schema: dsString(),
    storeId: dsString(),
    zipCode: dsString(),
    plaLocation: dsString(),
    carouselType: dsString(),
  }).shape({
    products: dsArrayOf(extend(
      dsShape({
        itemId: dsString(),
        identifiers: dsShape({
          canonicalUrl: dsString(),
        }),
        info: dsShape({
          sponsoredMetadata: dsShape({
            campaignId: dsString(),
            placementId: dsString(),
            slotId: dsString(),
            sponsoredId: dsString(),
            trackSource: dsString()
          }),
          sponsoredBeacon: dsShape({
            onClickBeacons: dsArrayOf(dsString()),
            onViewBeacons: dsArrayOf(dsString())
          }),
        })
      }),
      dsShape(SponsoredProductPod.dataModel.product),
    )),
    sponsoredReport: dsShape({
      numberOfSponsoredProducts: dsNumber(),
      numberOfMissingProductInformationProducts: dsNumber(),
      numberOfNonAvailableProducts: dsNumber(),
    }),
    carouselMetadata: dsShape({
      title: dsString(),
    }),
  })
});

/* eslint react/jsx-props-no-spreading: 0 */
const QueryProvidedSponsoredCarousel = (props) => {
  const { isClientResolved } = useContext(QueryContext);

  const skipFn = ({ skip, queryName }) => {
    if (queryName === 'plaModel') {
      const isBrowseSearch = props?.pageContext?.label === 'browse-search';
      const isSearchModelResolved = isClientResolved({ queryName: 'searchModel' });

      // skip if 'searchModel' is not resolved yet, to avoid double call to 'plaModel' at load time.
      if (isBrowseSearch && !isSearchModelResolved) return true;
    }

    return skip;
  };

  return (
    <QueryProvider cacheKey="sponsored-nucleus-carousel" skip={skipFn}>
      <SponsoredCarouselComponent {...props} />
    </QueryProvider>
  );
};

QueryProvidedSponsoredCarousel.propTypes = propTypes;

QueryProvidedSponsoredCarousel.defaultProps = defaultProps;

QueryProvidedSponsoredCarousel.dataModel = extend({}, SponsoredCarouselComponent);

const SponsoredCarousel = withErrorBoundary(
  withDynamicComponent(
    withHydrator(
      {
        id: 'sponsored-carousel',
        preserveCtxVal: 'clientStore'
      },
      QueryProvidedSponsoredCarousel
    )
  )
);
SponsoredCarousel.displayName = 'SponsoredCarousel';
export { SponsoredCarousel };
