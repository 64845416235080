class Sku {
  constructor(product, sqFtCoverage) {
    this._product = product;
    this._sqFtCoverage = sqFtCoverage;
  }

  get itemId() {
    const {
      itemId
    } = this._product?.identifiers || {};

    return itemId;
  }

  get unitOfMeasureCoverage() {
    const unitOfMeasureCoverage = (
      this._product?.pricing?.unitOfMeasureCoverage || this._product?.info?.unitOfMeasureCoverage
    );

    return unitOfMeasureCoverage;
  }

  get hidePrice() {
    const { hidePrice } = this._product?.info || {};
    return hidePrice;
  }

  get productSubType() {
    const { productSubType } = this._product?.info || {};
    return productSubType;
  }

  get category() {
    const { categoryHierarchy } = this._product?.info || {};
    return categoryHierarchy?.find((category) => category === 'Custom Kitchen Cabinets');
  }

  get rebates() {
    const ecoRebate = (this._product?.pricing?.ecoRebate || this._product?.info?.ecoRebate);

    if (ecoRebate) return { hasEcoRebates: true };

    return null;
  }

  get storePromotion() {
    const { description } = this._product?.pricing?.promotion || {};

    const { shortDesc, longDesc } = description || {};

    if (shortDesc !== 'INSTANT_REBATES') return null;

    return {
      longDescription: longDesc,
      shortDescription: shortDesc
    };
  }

  get maxPriceRange() {
    const sskMax = (this._product?.pricing?.sskMax || this._product?.info?.sskMax);
    return sskMax;
  }

  get minPriceRange() {
    const sskMin = (this._product?.pricing?.sskMin || this._product?.info?.sskMin);
    return sskMin;
  }

  get wasMinPriceRange() {
    const wasMinPriceRange = (this._product?.pricing?.wasMinPriceRange || this._product?.info?.wasMinPriceRange);
    return wasMinPriceRange;
  }

  get wasMaxPriceRange() {
    const wasMaxPriceRange = (this._product?.pricing?.wasMaxPriceRange || this._product?.info?.wasMaxPriceRange);
    return wasMaxPriceRange;
  }

  get quantityLimit() {
    const quantityLimit = (this._product?.pricing?.quantityLimit || this._product?.info?.quantityLimit);
    return quantityLimit;
  }

  get preferredPriceFlag() {
    if (this._product?.pricing === null || this._product?.pricing === undefined) {
      return false;
    }
    // forces boolean, so if the flag is null, it will return false
    return !!this._product.pricing.preferredPriceFlag;

  }

  get pricing() {
    const sqFtCoverage = this._sqFtCoverage;

    let {
      original: wasPrice,
      value: nowPrice,
    } = this._product?.pricing || {};

    const {
      alternatePriceDisplay,
      alternate,
      mapAboveOriginalPrice,
      message: pricingMessage,
      promotion,
      specialBuy: specialBuyPrice,
      unitOfMeasure: uom,
    } = this._product?.pricing || {};

    const {
      dollarOff,
      percentageOff,
      savingsCenter,
      savingsCenterPromos,
      specialBuySavings,
      specialBuyDollarOff,
      specialBuyPercentageOff
    } = promotion || {};

    const { bulk, unit } = alternate || {};

    const {
      value: bulkPrice,
      thresholdQuantity: bulkPriceThresholdQty
    } = bulk || {};

    const {
      unitsPerCase,
      caseUnitOfMeasure: caseUnitUom,
      unitsOriginalPrice: unitWasPrice,
      value: unitNowPrice
    } = unit || {};

    if (sqFtCoverage) {
      wasPrice *= sqFtCoverage;
      nowPrice *= sqFtCoverage;
    }

    const basicPrice = {
      uom,
      unitsPerCase,
      caseUnitUom,
      wasPrice,
      nowPrice,
      alternatePriceDisplay,
      unitWasPrice,
      unitNowPrice,
      dollarOff,
      percentageOff,
      bulkPrice,
      bulkPriceThresholdQty,
      specialBuyPrice,
      specialBuySavings,
      specialBuyDollarOff,
      specialBuyPercentageOff,
      pricingMessage,
      mapAboveOriginalPrice
    };
    const priceDetails = {
      itemId: this.itemId,
      savingsCenter,
      savingsCenterPromos,
      quantityLimit: this.quantityLimit,
      minPriceRange: this.minPriceRange,
      maxPriceRange: this.maxPriceRange,
      wasMinPriceRange: this.wasMinPriceRange,
      wasMaxPriceRange: this.wasMaxPriceRange,
      productSubType: this.productSubType,
      category: this.category,
      hidePrice: this.hidePrice,
      storePromotion: this.storePromotion,
      rebates: this.rebates,
      /*
        TODO: installationServiceType is never instantiated, so it causes a dead branch.
        If we plan on implementing this, check the PR comment below. If not, just remove it
        https://github.com/one-thd/ocm_pd_component_price/pull/198#discussion_r1202646418
      */
      installationServiceType: null,
      unitOfMeasureCoverage: this.unitOfMeasureCoverage,
    };

    return { ...basicPrice, ...priceDetails };
  }
}

export default Sku;
