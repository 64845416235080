import classNames from 'classnames';

const headingMappings = {
  h1: {
    element: 'h1',
    weightTypes: ['bold', 'display']
  },
  h2: {
    element: 'h2',
    weightTypes: ['bold', 'display']
  },
  h3: {
    element: 'h3',
    weightTypes: ['bold', 'display']
  },
  h4: {
    element: 'h4',
    weightTypes: ['bold', 'display']
  },
  h5: {
    element: 'h5',
    weightTypes: ['bold', 'display']
  },
  h6: {
    element: 'h6',
    weightTypes: ['bold', 'display']
  }
};

const bodyMappings = {
  'body-base': {
    element: 'p',
    weightTypes: ['regular', 'bold']
  },
  'body-lg': {
    element: 'p',
    weightTypes: ['regular', 'bold']
  },
  'body-xs': {
    element: 'p',
    weightTypes: ['regular', 'bold']
  }
};

const variantMapping = {
  ...headingMappings,
  ...bodyMappings
};

const colorClasses = {
  primary: 'sui-text-primary',
  subtle: 'sui-text-subtle',
  info: 'sui-text-info',
  success: 'sui-text-success',
  warning: 'sui-text-warning',
  danger: 'sui-text-danger',
  brand: 'sui-text-brand',
  inverse: 'sui-text-inverse',
  inactive: 'sui-text-inactive',
  none: ''
};

const alignClasses = {
  left: 'sui-text-left',
  center: 'sui-text-center',
  right: 'sui-text-right',
  justify: 'sui-text-justify'
};

const typographyClasses = {
  heading: {
    bold: ({ height, variant, decoration }) => classNames({
      'sui-h1-bold': variant === 'h1',
      'sui-h2-bold': variant === 'h2',
      'sui-h3-bold': variant === 'h3',
      'sui-h4-bold': variant === 'h4',
      'sui-h5-bold': variant === 'h5',
      'sui-h6-bold': variant === 'h6',
      'sui-leading-snug': height === 'snug' && (variant === 'h5' || variant === 'h6'),
      'sui-leading-none': height === 'none' && (variant !== 'h5' && variant !== 'h6'),
      'sui-underline': decoration === 'underline',
    }),
    display: ({ height, uppercase, variant, decoration }) => classNames({
      'sui-h1-display': variant === 'h1',
      'sui-h2-display': variant === 'h2',
      'sui-h3-display': variant === 'h3',
      'sui-h4-display': variant === 'h4',
      'sui-h5-display': variant === 'h5',
      'sui-h6-display': variant === 'h6',
      'sui-leading-snug': height === 'snug' && (variant === 'h5' || variant === 'h6'),
      'sui-leading-none': height === 'none' && (variant !== 'h5' && variant !== 'h6'),
      'sui-uppercase': uppercase,
      'sui-underline': decoration === 'underline',
    }),
  },
  body: {
    regular: ({ variant, height, decoration }) => classNames('sui-font-regular', {
      'sui-text-base': variant === 'body-base',
      'sui-text-xs': variant === 'body-xs',
      'sui-text-lg': variant === 'body-lg',
      'sui-leading-tight': height === 'tight',
      'sui-leading-normal': height === 'normal',
      'sui-leading-loose': height === 'loose' && variant === 'body-base',
      'sui-underline': decoration === 'underline',
      'sui-line-through': decoration === 'line-through'
    }, 'sui-tracking-normal sui-normal-case'),
    bold: ({ variant, height, decoration }) => classNames('sui-font-bold', {
      'sui-text-base': variant === 'body-base',
      'sui-text-xs': variant === 'body-xs',
      'sui-text-lg': variant === 'body-lg',
      'sui-leading-tight': height === 'tight',
      'sui-leading-normal': height === 'normal',
      'sui-leading-loose': height === 'loose' && variant === 'body-base',
      'sui-underline': decoration === 'underline',
      'sui-line-through': decoration === 'line-through'
    }, 'sui-tracking-normal sui-normal-case')
  },
};

const lineClampMapping = {
  default: {
    1: 'sui-line-clamp-1',
    2: 'sui-line-clamp-2',
    3: 'sui-line-clamp-3',
    4: 'sui-line-clamp-4',
    5: 'sui-line-clamp-5',
    6: 'sui-line-clamp-6',
    none: 'sui-line-clamp-unset',
  },
  sm: {
    1: 'sm:sui-line-clamp-1',
    2: 'sm:sui-line-clamp-2',
    3: 'sm:sui-line-clamp-3',
    4: 'sm:sui-line-clamp-4',
    5: 'sm:sui-line-clamp-5',
    6: 'sm:sui-line-clamp-6',
    none: 'sm:sui-line-clamp-unset',
  },
  md: {
    1: 'md:sui-line-clamp-1',
    2: 'md:sui-line-clamp-2',
    3: 'md:sui-line-clamp-3',
    4: 'md:sui-line-clamp-4',
    5: 'md:sui-line-clamp-5',
    6: 'md:sui-line-clamp-6',
    none: 'md:sui-line-clamp-unset',
  },
  lg: {
    1: 'lg:sui-line-clamp-1',
    2: 'lg:sui-line-clamp-2',
    3: 'lg:sui-line-clamp-3',
    4: 'lg:sui-line-clamp-4',
    5: 'lg:sui-line-clamp-5',
    6: 'lg:sui-line-clamp-6',
    none: 'lg:sui-line-clamp-unset',
  },
  xl: {
    1: 'xl:sui-line-clamp-1',
    2: 'xl:sui-line-clamp-2',
    3: 'xl:sui-line-clamp-3',
    4: 'xl:sui-line-clamp-4',
    5: 'xl:sui-line-clamp-5',
    6: 'xl:sui-line-clamp-6',
    none: 'xl:sui-line-clamp-unset',
  },
  '2xl': {
    1: '2xl:sui-line-clamp-1',
    2: '2xl:sui-line-clamp-2',
    3: '2xl:sui-line-clamp-3',
    4: '2xl:sui-line-clamp-4',
    5: '2xl:sui-line-clamp-5',
    6: '2xl:sui-line-clamp-6',
    none: '2xl:sui-line-clamp-unset',
  }
};

const expandClamp = (value = 'none') => {
  if (typeof value === 'object') {
    return Object.entries(value).map(([breakpoint, lines]) => {
      return lineClampMapping[breakpoint][lines];
    }).join(' ');
  }
  return lineClampMapping.default[value];
};

export default function createTypography({
  align,
  color,
  decoration,
  height,
  normalFontWeight = false,
  uppercase,
  truncate = false,
  lineClamp: lineClampProp,
  variant: variantProp,
  weight: weightProp
}) {

  const variant = variantMapping[variantProp] !== undefined ? variantProp : 'body-base';

  const isHeading = headingMappings[variant] !== undefined;

  const { element, weightTypes } = variantMapping[variant];

  const weight = weightTypes.indexOf(weightProp) !== -1 ? weightProp : weightTypes[0];

  const typeClasses = typographyClasses[isHeading ? 'heading' : 'body']?.[weight]?.({ height, variant, uppercase, decoration });

  const lineClamp = expandClamp(lineClampProp);

  const classes = [
    `${typeClasses} ${lineClamp}`,
    ...(truncate ? ['sui-truncate'] : []),
    ...(normalFontWeight ? ['sui-font-normal'] : []),
    ...(color ? [colorClasses[color]] : []),
    ...((align && align !== 'inherit') ? [alignClasses[align]] : [])
  ].join(' ');

  return {
    classes,
    element
  };
}