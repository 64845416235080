import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Image } from '@thd-olt-component-react/core-ui';
import { Carousel } from '@thd-olt-component-react/carousel';
import {
  useDataModel, params, string, shape,
  arrayOf, bool
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import { getCustomUrlWithAnalytics, isBuildAndBuyProduct } from '../../productPodUtils';
import { BUILD_AND_BUY, POD_IMAGE_SIZE } from '../../constants';

import styles from './product-image.module.scss';

const cx = classNames.bind(styles);

export const ProductImage = ({
  itemId,
  showSecondaryImage,
  dualImageSwipe,
  inCartQuantity,
  onClick,
  previewImgUrl,
  target = '_self',
  children
}) => {

  const { channel, hosts } = useContext(ExperienceContext);
  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductImage',
      name: 'ProductImage',
      position: 0
    }
  });
  const { parent } = additionalData || {};

  const [sponsoredValues, setSponsoredValues] = useState({});
  const [productState, setProductState] = useState({
    images: [],
    canonicalUrl: '',
    productType: '',
    productLabel: '',
    globalCustomConfigurator: {},
    augmentedReality: false
  });
  const [mounted, setMounted] = useState(false);

  const { data } = useDataModel('product', {

    variables: {
      itemId
    }
  });

  const { product } = data || {};

  const {
    media,
    info,
    identifiers
  } = product || {};
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (media?.images?.length) {
      setProductState({
        images: media?.images,
        canonicalUrl: identifiers?.canonicalUrl,
        productType: identifiers?.canonicalUrl,
        productLabel: identifiers?.canonicalUrl,
        globalCustomConfigurator: info?.canonicalUrl,
        augmentedReality: info?.canonicalUrl
      });
    }
  }, [media, info, identifiers]);

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredBeacon: sponsoredValues?.sponsoredBeacon || info?.sponsoredBeacon,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata
      });
    }
  }, [info?.isSponsored, info?.sponsoredBeacon, info?.sponsoredMetadata]);

  const canonicalUrl = identifiers?.canonicalUrl || productState.canonicalUrl;
  const productType = identifiers?.productType || productState.productType;
  const productLabel = identifiers?.productLabel || productState.productLabel;
  const globalCustomConfigurator = info?.globalCustomConfigurator || productState.globalCustomConfigurator;
  const augmentedReality = info?.augmentedReality || productState.augmentedReality;
  const images = media?.images || productState.images;

  const handleClick = (event, action = null) => {

    const customExperience = !!globalCustomConfigurator?.customExperience;
    if (!customExperience && onClick && action !== BUILD_AND_BUY) {
      onClick(event, product, action, sponsoredValues);
    }
    const productImageEventData = {
      podAction: action || 'product image',
      podAnchorSku: itemId,
      target,
      parent
    };
    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productImageEventData);
  };
  const productURL = getCustomUrlWithAnalytics({
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    sponsoredValues,
  });
  const imageUrl = (images?.find((img) => img.subType === 'PRIMARY') || {}).url;
  const secondaryImageUrl = (images?.find((img) => img.subType === 'SECONDARY') || {}).url;

  const secondaryImageClass = cx({
    'product-image--hover-secondary-image': showSecondaryImage,
    'product-image--secondary-image': !showSecondaryImage
  });

  const noProductImage = 'https://images.thdstatic.com/catalog/productImages/No_Image_145.jpg';
  const isMobile = channel === 'mobile';
  const imageTarget = isMobile ? '' : target;
  const showARIcon = isMobile && augmentedReality;
  const onBuildAndBuyImageClick = (event) => handleClick(event, BUILD_AND_BUY);
  const checkOnClick = isBuildAndBuyProduct(product) ? onBuildAndBuyImageClick : handleClick;

  const placeholderMessage = `${itemId} of product in the product pod`;
  const altLabel = productLabel || placeholderMessage;
  if (dualImageSwipe && imageUrl && secondaryImageUrl) {
    const childImages = [
      <Image
        key={imageUrl}
        stretchy
        src={imageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
        width={POD_IMAGE_SIZE}
        height={POD_IMAGE_SIZE}
        alt={altLabel}
        title={productLabel}
        lazy
      />,
      <Image
        key={secondaryImageUrl}
        stretchy
        src={secondaryImageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
        width={POD_IMAGE_SIZE}
        height={POD_IMAGE_SIZE}
        alt={altLabel}
        title={productLabel}
        lazy
      />
    ];
    if (showARIcon) {
      childImages.push(
        (
          <div data-testid="augemented-reality-wrapper" className={cx('augemented-reality-wrapper')}>
            <span className={cx('augemented-reality-wrapper__icon')} />
          </div>
        )
      );
    }
    return (
      <a
        href={productURL}
        onClick={checkOnClick}
        target={imageTarget}
      >
        <div className={cx('product-image-wrapper')}>
          <Carousel
            showArrows={false}
            showDots
            dotBelow
            itemWidthMobile="101%"
            itemClass={cx('product-image-wrapper__no-padding')}
          >
            {childImages}
          </Carousel>
          {children}
        </div>
      </a>
    );
  }

  const imageWrapperClasses = cx('product-image__wrapper', {
    'product-image__wrapper-overlay': inCartQuantity > 0,
  });

  return (
    <a
      href={productURL}
      onClick={checkOnClick}
      target={imageTarget}
      className={cx('product-image')}
    >
      <div
        className={imageWrapperClasses}
        data-quantity={inCartQuantity}
        data-testid="product-image__wrapper"
      >
        {imageUrl && (
          <Image
            stretchy
            className={cx({ 'product-image--has-secondary-image': secondaryImageUrl })}
            src={(previewImgUrl || imageUrl).replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
            width={POD_IMAGE_SIZE}
            height={POD_IMAGE_SIZE}
            alt={altLabel}
            title={productLabel}
            lazy
          />
        )}
        {secondaryImageUrl && (
          <Image
            stretchy
            className={secondaryImageClass}
            src={secondaryImageUrl.replace(/<SIZE>/g, POD_IMAGE_SIZE.toString())}
            width={POD_IMAGE_SIZE}
            height={POD_IMAGE_SIZE}
            alt={altLabel}
            title={productLabel}
            lazy
          />
        )}
        {(!imageUrl && !secondaryImageUrl && mounted) && (
          <Image
            stretchy
            className={cx({ 'product-image--has-secondary-image': secondaryImageUrl })}
            src={noProductImage}
            width={145}
            height={145}
            alt={altLabel}
            title={productLabel}
            lazy
          />
        )}
        {showARIcon && (
          <div data-testid="augemented-reality-wrapper" className={cx('augemented-reality-wrapper')}>
            <span className={cx('augemented-reality-wrapper__icon')} />
          </div>
        )}
        {children}
      </div>
    </a>
  );
};

ProductImage.propTypes = {
  showSecondaryImage: PropTypes.bool,
  dualImageSwipe: PropTypes.bool,
  inCartQuantity: PropTypes.number,
  itemId: PropTypes.string,
  onClick: PropTypes.func,
  previewImgUrl: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node)

};

ProductImage.defaultProps = {
  showSecondaryImage: false,
  dualImageSwipe: false,
  inCartQuantity: 0,
  itemId: null,
  onClick: () => null,
  previewImgUrl: '',
  target: '',
  children: null
};

ProductImage.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      augmentedReality: bool(),
      isSponsored: bool(),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
      }),
      globalCustomConfigurator: shape({
        customExperience: string()
      })
    }),
    identifiers: shape({
      canonicalUrl: string(),
      productType: string(),
      productLabel: string(),
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string(),
        sizes: arrayOf(string())
      }))
    }),
  }),
};

ProductImage.displayName = 'ProductImage';