import React, { useState, useContext } from 'react';
import { string, bool, func } from 'prop-types';
import { Button, Link } from '@one-thd/sui-atomic-components';
import { MOBILE } from '@thd-olt-functional/utils';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Drawer, DrawerItem, DrawerHeader } from '@thd-olt-component-react/drawer';
import classNames from 'classnames';

const CtaSection = ({
  buttonText, hideContactInfo, hideLabel, isFooter,
  buttonLink, contactNumber, onClick, openContentInDrawer,
  label, labelLink, target, headerDescription, dataComponent
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const isMobile = channel === MOBILE;
  const drawerMaxSize = isMobile ? 100 : 500;

  const handleOpen = (event) => {
    onClick(event);

    if (openContentInDrawer) {
      setIsDrawerOpen(true);
    } else {
      window.open(buttonLink, isConsumerApp ? '_self' : target);
    }
  };

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const wrapperClasses = classNames(
    'sui-flex',
    {
      'sui-flex-row-reverse': isFooter,
      'sui-flex-col': !isFooter
    }
  );

  const primaryCTAWrapperClasses = classNames({
    'sui-p-2': isFooter,
    'sui-flex-grow': isFooter,
    'sui-w-full': isFooter
  });

  const secondaryCTAWrapperClasses = classNames(
    'sui-text-sm sui-text-inactive sui-bg-button-secondary sui-w-full sui-text-center',
    {
      'sui-p-2': isFooter,
      'sui-flex-grow': isFooter,
      'sui-mt-2': !isFooter
    }
  );

  return (
    <div data-component={dataComponent} className={wrapperClasses}>
      <div className={primaryCTAWrapperClasses}>
        <Button
          fullWidth
          variant="primary"
          onClick={handleOpen}
        >
          {buttonText}
        </Button>
      </div>

      {
        !hideContactInfo && contactNumber && (
          <div className="sui-flex sui-justify-center sui-mt-2">
            <p className="sui-text-xs sui-text-subtle"> Or call&nbsp;
            </p>
            <Link variant="body-xs" href={`tel:${contactNumber}`}>
              {contactNumber}
            </Link>
          </div>
        )
      }
      {
        (!hideLabel && label && labelLink) && (
          <div
            className={secondaryCTAWrapperClasses}
          >
            {isFooter
              ? (
                <Button onClick={() => window.open(labelLink)} variant="text" fullWidth>
                  {label}
                </Button>
              )
              : (
                <Link target="_blank" rel="noreferrer" href={labelLink}>
                  {label}
                </Link>
              )}
          </div>
        )
      }
      {isDrawerOpen && (
        <Drawer
          isMobile={isMobile}
          maxSize={drawerMaxSize}
          open={isDrawerOpen}
          onClose={onDrawerClose}
          initialItem="product-quote"
        >
          <DrawerItem name="product-quote">
            <DrawerHeader title={headerDescription} />
            <iframe title="title" src={buttonLink} width="100%" height="99%" />
          </DrawerItem>
        </Drawer>
      )}
    </div>
  );
};

CtaSection.displayName = 'HomeServicesCtaSection';

CtaSection.propTypes = {
  hideContactInfo: bool,
  hideLabel: bool,
  buttonLink: string.isRequired,
  buttonText: string.isRequired,
  contactNumber: string,
  isFooter: bool,
  onClick: func,
  openContentInDrawer: bool,
  label: string,
  labelLink: string,
  target: string,
  headerDescription: string,
  dataComponent: string
};

CtaSection.defaultProps = {
  hideContactInfo: false,
  hideLabel: false,
  isFooter: false,
  contactNumber: '',
  onClick: () => { },
  openContentInDrawer: true,
  label: '',
  labelLink: '',
  target: '_blank',
  headerDescription: ' ',
  dataComponent: ''
};

export { CtaSection };