import { useEffect, useContext, useRef } from 'react';
import { ImpressionContext, impressionGlobalData } from './ImpressionContext';
import {
  ROOT_MARGIN,
  THRESHOLD,
  ROOT,
  IMPRESSION_WAIT_TIME,
  IMPRESSED,
  READY,
} from '../utils/constants';
import {
  impressionAnalytics,
  impressionFallbackCheck,
  impressionStringRefinement,
  createClickId,
} from '../utils/index';

const observerCallback = (entries) => {
  // eslint-disable-next-line no-restricted-syntax
  entries.forEach((entry) => {
    const clickIdAttribute = entry.target.getAttribute('clickid');
    let componentData = impressionGlobalData.impressionMap.get(clickIdAttribute);

    if (!componentData) return;

    if (!entry.isIntersecting) {
      clearTimeout(componentData.timeout);
      componentData.visibleSince = 0;
    }

    let entryIsVisible = entry.isIntersecting && !componentData.visibleSince;

    const executeImpression = () => {
      const impressed = createClickId({ data: componentData.impression, parent: componentData.container });
      impressionGlobalData.observer.unobserve(entry.target);
      // impressionGlobalData.impressionMap.delete(entry.target);
      if (componentData.name === impressionGlobalData.impressionMap.name) {
        impressionGlobalData.impressionMap.name = 'Page';
        impressionGlobalData.impressionMap.id = '';
      }
      if (impressionGlobalData.impressed[impressed] === IMPRESSED) return; // preventing re-impression
      impressionGlobalData.impressed[impressed] = IMPRESSED; // this is to update a global map of what was impressed
      impressionAnalytics({ impressionData: componentData });
    };

    if (entryIsVisible) {
      componentData.visibleSince = entry.time;
      componentData.timeout = setTimeout(() => {
        executeImpression();
      }, IMPRESSION_WAIT_TIME);
    }
  });
};

const useImpression = (props) => {
  const ref = useRef(null); // <-- Hooks violation, but only if Provider used improperly.

  const impressionRef = props?.ref ? props.ref : ref;

  let { data, additionalData, parent } = useContext(ImpressionContext);
  // this ^^^ is taking the object from impression context and
  // using it as a template to bring in values set in the data object
  // of the ImpressionProvider, or useImpression Hook.

  // allows the user to skip using multiple ImpressionProviders by passing in their own props to useImpression hook
  if (props) {
    parent = data;
    data = props.data;
  }
  impressionFallbackCheck(data, parent);

  impressionStringRefinement(data, parent);

  const clickID = createClickId({ data, parent });

  useEffect(() => {
    if (!impressionRef?.current) return;

    const currentImpressionMapData = impressionGlobalData.impressionMap.get(clickID);
    if (currentImpressionMapData && (currentImpressionMapData?.ref !== impressionRef.current)) {

      impressionGlobalData.observer.unobserve(currentImpressionMapData?.ref);

      impressionGlobalData.impressionMap.set(clickID, {
        impression: data,
        clickID,
        container: parent,
        ref: impressionRef.current,
      });
      impressionGlobalData.observer.observe(impressionRef.current);
    }
    const impressionStatus = impressionGlobalData.impressed[clickID];
    // Preventing adding empty ref and already `set` nodes to the map
    if (impressionStatus === IMPRESSED || impressionStatus === READY) return;

    impressionGlobalData.impressed[clickID] = READY;

    impressionGlobalData.impressionMap.set(clickID, {
      impression: data,
      clickID,
      container: parent,
      ref: impressionRef.current,
    });

    if (!impressionGlobalData.impressionRefArray.includes(clickID)) {
      impressionGlobalData.impressionRefArray.push(clickID);
    }

    if (!impressionGlobalData.observer) {
      impressionGlobalData.observer = new IntersectionObserver(
        observerCallback,
        {
          rootMargin: ROOT_MARGIN,
          threshold: THRESHOLD,
          root: ROOT,
        }
      );
    }
    impressionGlobalData.observer.observe(impressionRef.current);
  });
  return { ref: impressionRef, clickID, additionalData };
};

export { useImpression };
