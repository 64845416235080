import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from './ButtonBase';
import createTypography from '../utils/createTypography';

const buttonStylesMap = {
  loading: {
    base: 'sui-inline-flex sui-items-center !sui-text-transparent sui-pointer-events-none',
    active: {
      primary: '!sui-bg-button-primary',
      secondary: '!sui-bg-button-secondary'
    },
    inactive: {
      secondary: '!sui-bg-button-secondary sui-border-button-inactive'
    }
  }
};

const buttonStyles = {
  primary: ({ fullWidth, typography, disabled }) => classNames('sui-btn sui-btn-primary sui-btn-primary-focus', {
    'sui-w-full': fullWidth,
    'sui-w-full sm:sui-w-fit': !fullWidth,
    'sui-btn-primary-active sui-btn-primary-hover': !disabled,
    'sui-btn-primary-inactive': disabled
  }, typography),
  secondary: ({ disabled, fullWidth, typography }) => classNames('sui-btn sui-btn-secondary sui-btn-secondary-focus', {
    'sui-w-full': fullWidth,
    'sui-w-full sm:sui-w-fit': !fullWidth,
    'sui-btn-secondary-active sui-btn-secondary-hover': !disabled,
    'sui-btn-secondary-inactive': disabled
  }, typography),
  text: ({ disabled, typography }) => classNames('sui-btn sui-btn-text sui-btn-text-focus', {
    'sui-btn-text-active sui-btn-text-hover': !disabled,
    'sui-btn-text-inactive': disabled
  }, typography),
  ghost: ({ disabled, typography }) => classNames('sui-btn sui-btn-ghost sui-btn-ghost-focus', {
    'sui-btn-ghost-active sui-btn-ghost-hover': !disabled,
    'sui-btn-ghost-inactive': disabled
  }, typography)
};

const loadingButtonStyles = {
  primary: ({ buttonClasses, disabled }) => classNames(`${buttonClasses} ${buttonStylesMap.loading.base}`, {
    [`${buttonStylesMap.loading.active.primary}`]: !disabled
  }),
  secondary: ({ buttonClasses, disabled }) => classNames(`${buttonClasses} ${buttonStylesMap.loading.base}`, {
    [`${buttonStylesMap.loading.active.secondary}`]: !disabled,
    [`${buttonStylesMap.loading.inactive.secondary}`]: disabled
  }),
  text: ({ buttonClasses }) => classNames(`${buttonClasses} ${buttonStylesMap.loading.base}`),
  ghost: ({ buttonClasses }) => classNames(`${buttonClasses} ${buttonStylesMap.loading.base}`)
};

/**
 * The `Button` component comes with four variants: primary (default), secondary, text, and ghost.
 *
 * Related components: [ButtonGroup](#buttongroup), [IconButton](#iconbutton), [LoadingButton](#loadingbutton)
 *
 *
 * Usage:
 *
 * ```jsx
 * import { Button } from '@one-thd/sui-atomic-components';
 * ```
 */
const Button = React.forwardRef(({
  children,
  endIcon: EndIconComponent,
  startIcon: StartIconComponent,
  disabled = false,
  fullWidth = false,
  href,
  variant: variantProp,
  loading,
  ...rest
}, ref) => {

  const variant = buttonStyles[variantProp] ? variantProp : 'primary';

  const isPrimary = variant === 'primary' || !variant;
  const isText = variant === 'text';

  const typography = createTypography({
    variant: 'body-base',
    weight: isText ? 'regular' : 'bold',
    height: 'normal',
    color: 'none'
  });

  const buttonClasses = buttonStyles[isPrimary ? 'primary' : variant]({
    disabled,
    fullWidth,
    typography: typography.classes
  });

  const classes = loading ? loadingButtonStyles[isPrimary ? 'primary' : variant]({ buttonClasses, disabled }) : buttonClasses;

  const startIcon = StartIconComponent && (
    <span className="sui-mr-2 sui--ml-1" style={{ display: 'inherit' }}>
      <StartIconComponent size="small" />
    </span>
  );

  const endIcon = EndIconComponent && (
    <span className="sui-ml-2 sui--mr-1" style={{ display: 'inherit' }}>
      <EndIconComponent size="small" />
    </span>
  );

  return (
    <>
      {href && (
        <a
          href={href}
          className={classes}
          ref={ref}
          {...rest}
        >
          {startIcon}
          {children}
          {endIcon}
        </a>
      )}
      {!href && (
        <ButtonBase disabled={disabled || loading} ref={ref} unstyled className={classes} {...rest}>
          {startIcon}
          {children}
          {endIcon}
        </ButtonBase>
      )}
    </>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  /**
   * @ignore
   * Class override is for internal purposes only, will be removed shortly.
   * Use at your own risk.
   */
  children: PropTypes.node,
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * An icon to display after the text inside the button. Needs to be a React React element type (ie. MyComponent)
   * Needs to be a React element type (ie. MyIcon), not an instance (ie. <MyIcon />)
   */
  endIcon: PropTypes.elementType,
  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * The URL to link to when the button is clicked.
   * If defined, an `a` element will be used as the root node.
   */
  href: PropTypes.string,
  /**
   * @ignore
   * If true applies the appropriate classes to convert it into a LoadingButton, for internal use only
   */
  loading: PropTypes.bool,
  /**
   * Callback fired when the component is clicked.
   */
  onClick: PropTypes.func,
  /**
   * An icon to display before the text inside the button.
   * Needs to be a React element type (ie. MyIcon), not an instance (ie. <MyIcon />)
   */
  startIcon: PropTypes.elementType,
  /**
   * The variant to use.
   * @default 'primary'
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'text', 'ghost']),
};

export { Button };
