import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AccordionContext from './AccordionContext';
import { ButtonBase } from '../private/components/button/ButtonBase';
import { Typography } from '../typography/Typography';
import { ArrowDown } from '../private/icons/ArrowDown';

function createComponent(Component, displayName) {

  const StyledComponent = React.forwardRef((props, ref) => {
    const {
      children,
      className,
      component,
      ...other
    } = props;

    const FinalComponent = Component;

    return (
      <FinalComponent component={component} ref={ref} className={className} {...other}>
        {children}
      </FinalComponent>
    );
  });

  StyledComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    className: PropTypes.string,
    component: PropTypes.elementType
  };

  StyledComponent.displayName = displayName;
  return StyledComponent;
}

const AccordionHeaderRoot = createComponent(ButtonBase, 'AccordionHeaderRoot');
const AccordionHeaderContent = createComponent('div', 'AccordionHeaderContent');
const AccordionHeaderExpandIconWrapper = createComponent('div', 'AccordionHeaderExpandIconWrapper');

/**
 * `AccordionHeader` houses the title and icon in [Accordion](#accordion)
 *
 * Use it along with [AccordionBody](#accordionbody)
 *
 * Usage:
 *
 * ```jsx
 * import { AccordionHeader } from '@one-thd/sui-atomic-components';
 * ```
 */
const AccordionHeader = React.forwardRef((props, ref) => {

  const {
    children: childrenProp,
    expandIcon: expandIconProp,
    onClick,
    ...other
  } = props;

  const { disabled = false, expanded, toggle } = React.useContext(AccordionContext);

  const handleChange = (event) => {
    if (toggle) {
      toggle(event);
    }
    if (onClick) {
      onClick(event);
    }
  };

  let children;
  if (typeof childrenProp === 'string') {
    children = (<Typography color="none" variant="body-lg" weight="bold" height="tight">{childrenProp}</Typography>);
  } else {
    children = childrenProp;
  }

  const AccordionHeaderExpandClasses = classNames('sui-flex sui-ml-4 sui-transition-transform sui-duration-250', {
    'group-focus-visible:sui-fill-inverse group-hover:sui-fill-brand': !disabled,
    'sui-fill-subtle': disabled,
    'sui-fill-primary': !expanded && !disabled,
    'sui-rotate-180': expanded,
  });

  const expandIcon = expandIconProp || <ArrowDown size="small" color="inherit" />;

  const AccordionHeaderRootClasses = classNames('sui-group sui-min-h-12 sui-rounded-base sui-flex sui-px-4 sui-border-1 sui-border-primary sui-border-solid hover:sui-bg-subtle focus-visible:sui-border-input-focus focus-visible:sui-border-4 focus-visible:sui-bg-inverse focus-visible:sui-text-inverse', {
    'sui-bg-button-inactive-primary': disabled
  });

  return (
    <AccordionHeaderRoot
      disabled={disabled}
      component="div"
      aria-expanded={expanded}
      className={AccordionHeaderRootClasses}
      onClick={handleChange}
      ref={ref}
      {...other}
    >
      <AccordionHeaderContent className="sui-flex sui-grow sui-my-4 group-focus-visible:sui-my-[13px]">
        {children}
      </AccordionHeaderContent>
      {expandIcon && (
        <AccordionHeaderExpandIconWrapper className={AccordionHeaderExpandClasses}>
          {expandIcon}
        </AccordionHeaderExpandIconWrapper>
      )}
    </AccordionHeaderRoot>
  );
});

AccordionHeader.displayName = 'AccordionHeader';

AccordionHeader.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The icon to display as the expand indicator.
   */
  expandIcon: PropTypes.node,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
};

export { AccordionHeader };
