import React from 'react';
import PropTypes from 'prop-types';

const LoadingIcon = ({ color = 'currentColor' }) => {
  return (
    <svg className="sui-animate-spin" width="20" height="20" viewBox="0 0 24 24" data-testid="LoadingIcon">
      <path
        d="M0 12a12 12 0 1024 0 12 12 0 10-24 0M4 12a8 8 0 1016 0 8 8 0 10-16 0"
        className="sui-opacity-25"
        fill={color}
        fillRule="evenodd"
      />
      <path
        className="sui-opacity-75"
        fill={color}
        d="M 4 12 a 8 8 0 0 1 8 -8 V 0 C 5.373 0 0 5.373 0 12 h 4 z m 2 5.291 A 7.962 7.962 0 0 1 4 12 H 0 c 0 3.042 1.135 5.824 3 7.938 l 3 -2.647"
      />
    </svg>
  );
};
LoadingIcon.displayName = 'LoadingIcon';
LoadingIcon.propTypes = {
  color: PropTypes.string
};

export { LoadingIcon };