import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import * as constants from './constants';

const {
  CONFIGURABLE_BLINDS, HOME_DEPOT_DESIGN_CENTER, SCHEDULE_A_MEASURE_URL
} = constants;

const {
  isLiveGoods,
  getSellableQuantityYourStore
} = podFulFillmentUtils;

export const isBuildAndBuyProduct = (product) => {
  const { identifiers } = product || {};

  return identifiers?.productType === CONFIGURABLE_BLINDS;
};

const isCustomCabinet = (productSubType) => {
  const { link = '' } = productSubType || {};
  const checkCustomCabinet = /BRIO_INAISLE_SFI_CABINETS/.test(link);
  return checkCustomCabinet;
};

export const checkScheduleAMeasureEligibility = (product) => {
  return !!(product?.installServices?.scheduleAMeasure);
};

export const isBrioProduct = (product) => {
  const { info } = product || {};
  const { isBrioSku, isCustomKitchenCabinet, productSubType } = info || {};
  return (isBrioSku && !(isCustomCabinet(productSubType) || isCustomKitchenCabinet));
};

export const isCustomKitchenCabinetProduct = (product) => {
  const { info } = product || {};
  const { isBrioSku, isCustomKitchenCabinet, productSubType } = info || {};
  return (isBrioSku && (isCustomCabinet(productSubType) || isCustomKitchenCabinet));
};

export const isLiveGoodsOOSProduct = (product) => {
  return (isLiveGoods(product) && !getSellableQuantityYourStore(product));
};

export const getCustomBlindsUrl = ({
  productType,
  blindsHost,
  canonicalUrl,
}) => {
  const configureProduct = productType === CONFIGURABLE_BLINDS;
  const regex = /\/p\//gi;
  const customBlindsPath = '/p/custom-blinds/';
  const customProductUrl = canonicalUrl && canonicalUrl.replace(regex, customBlindsPath);

  return (configureProduct) ? `${blindsHost}${customProductUrl}` : canonicalUrl;
};

export const getCustomUrlWithAnalytics = ({
  productType,
  blindsHost,
  canonicalUrl
}) => {
  let productUrl = getCustomBlindsUrl({
    productType, blindsHost, canonicalUrl,
  });

  return productUrl;
};

export const isHDDCSku = (product) => {
  return product?.details?.installation?.serviceType === HOME_DEPOT_DESIGN_CENTER
    || product?.info?.productSubType?.name === HOME_DEPOT_DESIGN_CENTER;
};
