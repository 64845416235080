import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../typography/Typography';

const Eyebrow = (props) => {

  const {
    ornament: ornamentProp,
    title: titleProp,
    ...other
  } = props;

  let ornament = ornamentProp;
  if (ornament != null) {
    ornament = (
      <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto sui-mr-2 sui-text-brand">
        {ornament}
      </div>
    );
  }

  let title = titleProp;
  if (title != null) {
    title = (
      <div className="sui-flex-auto">
        <Typography color="brand" weight="bold" height="tight">{title}</Typography>
      </div>
    );
  }
  return (
    <div className="sui-flex sui-items-center">
      {ornament}
      {title}
    </div>
  );
};

Eyebrow.propTypes = {
  /**
   * The contents of the eyebrow.
   */
  title: PropTypes.string,
  /**
   * The leading ornament within the eyebrow.
   */
  ornament: PropTypes.node
};

export { Eyebrow };