const hasSpecialOffer = (skuModel) => {
  const specialOfferFound = skuModel._product.pricing?.value && skuModel._product.pricing?.value > 0;
  return specialOfferFound;
};

const hasPercentOff = (skuModel) => {
  return (
      skuModel._product.pricing?.promotion?.percentageOff
      && skuModel._product.pricing?.promotion?.percentageOff > 0
  );
};

export const isBasicPerItemPrice = (skuModel, quantity) => {
  return quantity > 1 && !hasPercentOff(skuModel);
};

export const isStrikeThroughPrice = (skuModel, quantity) => {
  const isStrikeThrough = quantity <= 1 && hasSpecialOffer(skuModel) && hasPercentOff(skuModel);
  return isStrikeThrough;
};

export const isStrikeThroughPerItemPrice = (skuModel, quantity) => {
  return quantity > 1 && hasSpecialOffer(skuModel) && hasPercentOff(skuModel);
};

export const LAYOUT_TYPES = Object.freeze({
  MINIMAL: 'minimal',
  DETAILED: 'detailed',
  SIMPLE: 'simple',
});