import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, shape, bool, useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import { Rating } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames/bind';
import { getCustomUrlWithAnalytics } from '../../productPodUtils';
import styles from './product-rating.module.scss';

const cx = classNames.bind(styles);

export const ProductRating = (props) => {
  const { itemId, target } = props;

  const { hosts } = useContext(ExperienceContext);
  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductRating',
      name: 'ProductRating',
      position: 0
    }
  });

  const { parent } = additionalData || {};

  const [sponsoredValues, setSponsoredValues] = useState({});
  const [reviewsData, setReviewsData] = useState({});

  const { data } = useDataModel('product', {
    variables: {
      itemId
    }
  });

  const { product } = data || {};
  const { info, identifiers, reviews } = product || {};

  useEffect(() => {
    if (reviews) {
      setReviewsData({
        totalReviews: reviews?.ratingsReviews?.totalReviews,
        averageRating: reviews?.ratingsReviews?.averageRating,
        canonicalUrl: identifiers?.canonicalUrl,
        productType: identifiers?.productType
      });
    }
  }, [reviews, identifiers?.canonicalUrl, identifiers?.productType]);

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredBeacon: sponsoredValues?.sponsoredBeacon || info?.sponsoredBeacon,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata
      });
    }
  }, [info?.isSponsored, info?.sponsoredBeacon, info?.sponsoredMetadata]);

  let totalReviews = reviews?.ratingsReviews?.totalReviews || reviewsData?.totalReviews;
  let averageRating = reviews?.ratingsReviews?.averageRating || reviewsData?.averageRating;

  if (!totalReviews && !averageRating && !reviews) return null;

  const canonicalUrl = identifiers?.canonicalUrl || reviewsData?.canonicalUrl;
  const productType = identifiers?.productType || reviewsData?.productType;

  const hasRatings = Boolean(
    typeof averageRating === 'string'
    && typeof totalReviews === 'string'
    && Number(totalReviews) > 0
  );

  if (!hasRatings) return null;

  totalReviews = parseInt(totalReviews, 10);
  averageRating = parseFloat(averageRating);

  const productURL = getCustomUrlWithAnalytics({
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    info,
    sponsoredValues
  });

  const handleClick = () => {
    const productRatingEventData = {
      podAction: 'product rating',
      podAnchorSku: itemId,
      target,
      parent
    };

    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productRatingEventData);
  };

  return (
    <a
      href={`${productURL}#ratings-and-reviews`}
      target={target}
      data-testid="product-pod__ratings-link"
      onClick={handleClick}
    >
      <div className={cx('ratings')}>
        <Rating value={averageRating} noMargin />
        <span className={cx('ratings__count')}>({totalReviews})</span>
      </div>
    </a>
  );
};

ProductRating.displayName = 'ProductRating';

ProductRating.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string(),
    itemId: string(),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string(),
      })
    }),
    info: shape({
      isSponsored: bool(),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
        onViewBeacon: string()
      }),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
      globalCustomConfigurator: shape({
        customExperience: string()
      })
    }),
    identifiers: shape({
      canonicalUrl: string(),
      productType: string(),
    }),
  }),
};

ProductRating.propTypes = {
  itemId: PropTypes.string.isRequired,
  target: PropTypes.string
};

ProductRating.defaultProps = {
  target: null,
};
