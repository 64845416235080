/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './layout.module.scss';

const cx = classNames.bind(styles);

const renderChildren = (children) => {
  if (!children) {
    return <></>;
  }

  const elements = Array.isArray(children) ? children : [children];
  return (<>{elements.map((child) => child)}</>);
};

export const PodSection = ({
  children, columnAlign, noPadding, relative, margin, alignTop
}) => {
  const sectionClass = cx('pod-section', {
    'pod-section--column-align': columnAlign,
    'pod-section--nopadding': noPadding,
    'pod-section--relative': relative,
    'pod-section--margin': margin,
    'pod-section--align-top': alignTop
  });
  return (
    <div className={sectionClass}>
      { renderChildren(children) }
    </div>
  );
};

PodSection.propTypes = {
  columnAlign: PropTypes.bool,
  noPadding: PropTypes.bool,
  relative: PropTypes.bool,
  margin: PropTypes.bool,
  alignTop: PropTypes.bool,
};
PodSection.defaultProps = {
  columnAlign: false,
  noPadding: false,
  relative: false,
  margin: false,
  alignTop: false
};

export const PodHeader = ({ children }) => <div className={cx('pod-header')}>{renderChildren(children)}</div>;
export const PodColumn = ({ children, minWidth }) => (
  <div style={{ minWidth }} className={cx('pod-column')}>
    {renderChildren(children)}
  </div>
);
export const PodFooter = ({ children, noPadding }) => (
  <div className={cx('pod-footer', { 'pod-no-padding': noPadding })}>{renderChildren(children)}</div>
);

export const PodSticker = ({ children, position = 'top-left' }) => {
  return (
    <div className={cx('pod-sticker', `pod-sticker__${position}`)}>{renderChildren(children)}</div>
  );
};

export const PodSpacer = ({ children, padding, minHeight, disable }) => {
  if (disable) return null;

  const paddingClasses = cx({
    'pod-spacer': padding.includes('ALL'),
    'pod-spacer__left': padding.includes('LEFT'),
    'pod-spacer__right': padding.includes('RIGHT'),
    'pod-spacer__top': padding.includes('TOP'),
    'pod-spacer__bottom': padding.includes('BOTTOM'),
  });
  const style = minHeight ? { minHeight } : {};
  return (
    <div style={style} className={paddingClasses}>{renderChildren(children)}</div>
  );
};

PodSpacer.propTypes = {
  padding: PropTypes.arrayOf(
    PropTypes.oneOf(['LEFT', 'RIGHT', 'TOP', 'BOTTOM', 'ALL'])
  ),
  minHeight: PropTypes.string
};
PodSpacer.defaultProps = {
  padding: [],
  minHeight: null
};
