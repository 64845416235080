import React from 'react';
import { string } from 'prop-types';
import {
  params,
  shape,
  namedFragment,
  customType,
  string as stringType,
  bool as boolType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { RevjetBundle } from '@thd-olt-component-react/revjet_bundle';
import classNames from 'classnames/bind';
/**
 *
 * @author: This sub-component is owned by the Omnichannel Personalization Team.
 * For any changes or concerns, our Slack channel is #omnichannel-personalization
 */
const HeroPersonalized = ({ componentId, componentClass, thdPageId, searchParams }) => {

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    }
  });

  const queryParams = new URLSearchParams(searchParams);
  const recommendationValue = queryParams.get('__recommendation_id');
  const revJetContent = data?.component?.personalizedContent || {};

  const getThdPageId = () => {
    const id = thdPageId || '';
    const hasB2C = id.indexOf('-b2c') !== -1;
    const hasB2B = id.indexOf('-b2b') !== -1;

    if (!hasB2C && !hasB2B) {
      return id;
    }

    return id.slice(0, id.length - 4);
  };

  const pageId = getThdPageId();

  return (
    <div className="sui-h-[440px] sui-max-h-[440px] hero-personalized">
      <RevjetBundle
        tag={revJetContent?.tag || ''}
        plcId={revJetContent?.plcId || ''}
        keyId={revJetContent?.keyId || ''}
        omsid={revJetContent?.omsid || ''}
        deliveryWrapper={revJetContent?.optsDeliveryWrapper || ''}
        autohide={revJetContent?.optsAutohide}
        responsive={revJetContent?.optsResponsive}
        responsiveHeight={revJetContent?.optsResponsiveHeight || ''}
        autoscale={revJetContent?.optsAutoscale}
        deliveryMethod={revJetContent?.optsDeliveryMethod || ''}
        impBannerSize={revJetContent?.optsImpBannerSize || ''}
        deliveryFormat={revJetContent?.optsDeliveryFormat || ''}
        recommendationId={recommendationValue}
        thdPageId={pageId}
      />
    </div>
  );
};

HeroPersonalized.propTypes = {
  componentId: string.isRequired,
  componentClass: string.isRequired,
  searchParams: string,
  thdPageId: string
};

HeroPersonalized.defaultProps = {
  searchParams: '',
  thdPageId: ''
};

HeroPersonalized.displayName = 'HeroPersonalized';

RevjetBundle.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['RevJetModule']).isRequired(),
  }).shape({
    RevJetModule: namedFragment({ inline: true, fragmentType: 'RevJetModule' }).shape({
      id: stringType(),
      tag: stringType().isRequired(),
      keyId: stringType().isRequired(),
      plcId: stringType(),
      omsid: stringType(),
      optsDeliveryWrapper: stringType(),
      optsResponsive: boolType(),
      optsResponsiveHeight: stringType(),
      optsImpBannerSize: stringType(),
      optsDeliveryMethod: stringType(),
      optsDeliveryFormat: stringType(),
      optsAutohide: boolType(),
      optsAutoscale: boolType(),
    })
  })
};

HeroPersonalized.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['HeroPersonalized']).isRequired(),
  }).shape({
    HeroPersonalized: namedFragment({ inline: true, fragmentType: 'HeroPersonalized' }).shape({
      id: stringType(),
      name: stringType(),
      personalizedContent: shape({
        ...RevjetBundle.dataModel.component
      })
    })
  })
};

export { HeroPersonalized };