import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
  params, string, bool, shape, useDataModel
} from '@thd-nucleus/data-sources';
import styles from './ProductSponsored.style.scss';

const cx = classNames.bind(styles);

export const ProductSponsored = ({ position, itemId, noBackground }) => {
  const { data } = useDataModel('product', {
    variables: {
      itemId,
    },
  });

  const [sponsoredValues, setSponsoredValues] = useState({});
  const { info } = data?.product || {};

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredBeacon: sponsoredValues?.sponsoredBeacon || info?.sponsoredBeacon,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata
      });
    }
  }, [info?.isSponsored, info?.sponsoredBeacon, info?.sponsoredMetadata]);

  if (!data?.product) return null;
  const { isSponsored, sponsoredBeacon } = sponsoredValues;

  if (!isSponsored) return null;

  const sponseredStyle = cx('product-sponsored', {
    'product-sponsored--no-background': noBackground
  });

  return (
    <div
      className={sponseredStyle}
      id={'plp_pod_sponsored_' + position}
      data-onview-beacon={sponsoredBeacon?.onViewBeacon}
      data-onclick-beacon={sponsoredBeacon?.onClickBeacon}
    >
      <span className={cx('product-sponsored__text')}>Sponsored</span>
    </div>
  );
};

ProductSponsored.propTypes = {
  position: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  noBackground: PropTypes.bool
};

ProductSponsored.defaultProps = {
  noBackground: false
};

ProductSponsored.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      isSponsored: bool(),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
        onViewBeacon: string()
      }),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
    }),
  }),
};

ProductSponsored.displayName = 'ProductSponsored';
