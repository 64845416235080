import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '../button/IconButton';
import { Typography } from '../typography/Typography';
import { Close } from '../private/icons/Close';
import { ErrorFilled } from '../private/icons/ErrorFilled';
import { InfoFilled } from '../private/icons/InfoFilled';
import { ConfirmedFilled } from '../private/icons/ConfirmedFilled';
import { WarningFilled } from '../private/icons/WarningFilled';

const iconMapping = {
  success: <ConfirmedFilled size="regular" color="success" />,
  warning: <WarningFilled size="regular" color="warning" />,
  error: <ErrorFilled size="regular" color="danger" />,
  info: <InfoFilled size="regular" color="info" />,
};

/**
 * An `Alert` informs customers about important changes or persistent conditions.
 * Use this component if you need to communicate important information to a customer
 * or prompt a customer to take an action.
 *
 * Related components: [Typography](#typography), [Link](#link)
 *
 * Usage:
 *
 * ```jsx
 * import { Alert } from '@one-thd/sui-atomic-components';
 * ```
 */
const Alert = React.forwardRef((props, ref) => {

  const {
    action,
    children: childrenProp,
    closeText = 'Close',
    onClose,
    status,
    ...other
  } = props;

  const classes = classNames(
    'sui-flex sui-w-full sui-bg-subtle sui-p-4 sui-px-4 sui-py-3 sui-border-l-4 sui-border-solid',
    {
      'sui-border-l-stronger': status === undefined,
      'sui-border-l-danger': status === 'error',
      'sui-border-l-info': status === 'info',
      'sui-border-l-success': status === 'success',
      'sui-border-l-warning': status === 'warning',
    },
  );

  const AlertIcon = iconMapping[status];

  let children;
  if (typeof childrenProp === 'string') {
    children = (
      <Typography color="primary" component="span">
        {childrenProp}
      </Typography>
    );
  } else {
    children = childrenProp;
  }

  return (
    <div
      className="sui-mb-4 sui-border-1 sui-border-solid sui-border-primary sui-rounded-base"
      role="alert"
      ref={ref}
      {...other}
    >
      <div className={classes}>
        {AlertIcon != null ? (
          <div className="sui-flex sui-mr-4 sui-py-2 sui-px-0">
            {AlertIcon}
          </div>
        ) : null}
        <div className="sui-py-2 sui-px-0 sui-min-w-0 sui-overflow-auto">
          {children}
        </div>
        {action != null ? (
          <div className="sui-flex sui-align-start sui-pt-1 sui-pb-0 sui-pr-0 sui-pl-4 sui-ml-auto sui--mr-2">
            {action}
          </div>
        ) : null}
        {action == null && onClose ? (
          <div className="sui-flex sui-align-start sui-pt-1 sui-pb-0 sui-pr-0 sui-pl-4 sui-ml-auto sui--mr-2">
            <IconButton
              icon={Close}
              iconSize="small"
              onClick={onClose}
              aria-label={closeText}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
});

Alert.displayName = 'Alert';

Alert.propTypes = {
  /**
   * The action to display. It renders after the message, at the end of the alert.
   * @ignore
   */
  action: PropTypes.node,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The label for the close popup icon button
   */
  closeText: PropTypes.string,
  /**
   * Callback fired when the component requests to be closed.
   * Displayed when provided and no `action` prop is set.
   */
  onClose: PropTypes.func,
  /**
   * The status of the alert. This defines the color and icon used.
   */
  status: PropTypes.oneOf(['error', 'info', 'success', 'warning'])
};

export { Alert };
