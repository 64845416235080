import React, { useContext } from 'react';
import classNames from 'classnames';
import { node, string } from 'prop-types';
import { Image, Paper } from '@thd-olt-component-react/core-ui';
import { DrawerContext } from '../Drawer';
import styles from './drawer-header.module.scss';

/**
 * Header in Drawer
 * This is has a general close button
 * @param {*} param0
 */
export const DrawerHeader = ({
  children,
  title
}) => {
  const { closeDrawer, setPreviousDrawer, isFlex } = useContext(DrawerContext);

  /**
   * Helps to navigate to the previous drawer
   * @param {*} event
   */
  const goBack = (event) => {
    event.preventDefault();
    setPreviousDrawer();
  };

  const navClasses = classNames(
    styles['drawer-header'],
    styles['sticky-nav'],
    styles['sticky-nav--top'],
    {
      [styles['default-header']]: !children,
      [styles['sticky-nav--flex']]: isFlex
    }
  );

  return (
    <Paper elevation={2} sharp className={navClasses}>
      <nav>
        {title
          ? <span className={styles['drawer-header__title']} data-testid="drawer-title">{title}</span>
          : (
            <button
              type="button"
              onClick={goBack}
              className={styles['drawer-header-back']}
              data-testid="drawer-header-back"
            >
              <Image width={20} height={20} asset="caret-orange.svg" alt="Back" />
              Back
            </button>
          )}
        <button
          type="button"
          onClick={(event) => closeDrawer(event, true)}
          className={styles.close}
          data-testid="drawer-header-close"
        >
          <Image width={20} height={20} asset="close-symbol-orange.svg" alt="Close" />
        </button>
      </nav>
      {children}
    </Paper>
  );
};

DrawerHeader.displayName = 'DrawerHeader';

DrawerHeader.propTypes = {
  children: node,
  title: string
};

DrawerHeader.defaultProps = {
  children: null,
  title: null
};