import React, { useEffect } from 'react';
import {
  array,
  bool,
  string,
  oneOfType,
  shape,
  func,
  number,
  node
} from 'prop-types';
import { useDomPath } from '@thd-olt-functional/utils';
import { Button, LoadingButton } from '@one-thd/sui-atomic-components';
import { Cart } from '@one-thd/sui-icons';

const AddToCart = ({
  cartReqParams,
  cartOptions,
  disabled,
  loading,
  onAddToCartClick,
  children,
  showBOPISOverlay,
  onClick,
  onClickPassThrough,
  onSuccess,
  onFail,
  onError,
  fullWidth,
  showIcon,
  variant
}) => {
  useEffect(() => {
    if (!loading) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('add-to-cart.ready');
    }
  }, []);

  const [path, ref] = useDomPath();

  return (
    <div data-component="AddToCart">
      <LoadingButton
        ref={ref}
        variant={variant}
        disabled={disabled}
        startIcon={showIcon ? Cart : undefined}
        fullWidth={fullWidth}
        loading={loading}
        onClick={(event) => {
          window.LIFE_CYCLE_EVENT_BUS.trigger('NATIVE_APP.ADD_TO_CART', {
            quantity: cartReqParams.quantity
          });
          if (disabled && onError) {
            onError();
          } else if (onClickPassThrough) {
            onClickPassThrough(cartReqParams);
          } else if (!disabled) {
            if (onClick) onClick();
            if (showBOPISOverlay) {
              window.LIFE_CYCLE_EVENT_BUS.trigger('cart.select-store', {
                cartReqParams
              });
            } else {
              window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
                cartReqParams,
                cartOptions,
                onAddToCartClick,
                onSuccess,
                onFail,
                path
              });
            }
          }
        }}
      >
        {children}
      </LoadingButton>
    </div>
  );
};

AddToCart.dataModel = {};
AddToCart.displayName = 'AddToCart';

AddToCart.propTypes = {
  cartOptions: shape({
    directCheckout: bool,
    host: string,
    misship: bool,
    paypal: bool
  }),
  cartReqParams: oneOfType([
    array,
    shape({
      attachedLabor: shape({
        attachedLaborSku: string
      }),
      fulfillmentLocation: string,
      fulfillmentMethod: string,
      itemId: string,
      quantity: number,
      service: shape({
        selected: bool,
        type: string
      }),
      subscriptions: shape({
        frequency: string
      }),
      warrantyItem: shape({
        itemId: string
      }),
    })
  ]),
  children: oneOfType([string, node]).isRequired,
  disabled: bool,
  fullWidth: bool,
  loading: bool,
  onAddToCartClick: func,
  onClick: func,
  onClickPassThrough: func,
  onError: func,
  onFail: func,
  onSuccess: func,
  showBOPISOverlay: bool,
  showIcon: bool,
  variant: string
};

AddToCart.defaultProps = {
  cartOptions: {
    channel: 'desktop',
    host: 'https://www.homedepot.com',
    misship: false,
    paypal: false
  },
  cartReqParams: {
    itemId: null,
    quantity: 1
  },
  disabled: false,
  fullWidth: true,
  loading: false,
  onAddToCartClick: () => {},
  onClick: () => {},
  onClickPassThrough: null,
  onError: null,
  onFail: () => {},
  onSuccess: () => {},
  showBOPISOverlay: false,
  showIcon: false,
  variant: 'primary'
};

export { AddToCart };
