import React from 'react';
import PropTypes from 'prop-types';
import {
  useDataModel,
  extend,
  arrayOf,
  namedFragment,
  customType,
  bool as boolType,
  shape,
  string as stringType,
  alias
} from '@thd-nucleus/data-sources';
import classNames from 'classnames';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { Carousel } from '@one-thd/sui-carousel';
import { Typography, useBreakpoint } from '~/@one-thd/sui-atomic-components/dist/index';
import { Spotlight } from './Spotlight';
import { configureContainerForCarousel, configureContainerForWrap } from './utils/spotlightContainerConfig';

const SpotlightContainer = ({
  componentId,
  componentClass,
  componentPosition,
  showDescription,
  useB2bStyles,
}) => {
  const { current: currentBreakpoint } = useBreakpoint(null, { current: true });

  const { data, loading, error } = useDataModel('specializedContainer', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  if (!data || loading || error) {
    return null;
  }

  const title = data?.component?.title;
  const overflowBehavior = data?.component?.overflowBehavior;
  const extraCardOnLargeAndAbove = data?.component?.extraCardOnLargeAndAbove;
  const spotlightList = data?.component?.spotlightList;
  const cardCount = spotlightList?.length;
  const spotlightOrientation = (
    cardCount === 1
    && currentBreakpoint !== 'unprefixed'
    && currentBreakpoint !== 'sm'
  ) ? 'horizontal' : 'vertical';

  const spotlightComponents = spotlightList.map((spotlight, index) => (
    <Spotlight
      key={spotlight.id}
      componentId={spotlight.id}
      componentClass="Spotlight"
      componentPosition={index + 1}
      showDescription={showDescription}
      useB2bStyles={useB2bStyles}
      orientation={spotlightOrientation}
    />
  ));

  const wrapContainer = (
    <div data-testid="spotlight-wrap-container">
      {title && (
        <div data-component-id={componentId} className="sui-pb-4">
          <Typography variant="h2" weight="display" height="snug" color="primary" uppercase>{title}</Typography>
        </div>
      )}
      <div className={classNames(
        'sui-grid', 'sui-gap-4', 'md:sui-gap-6', 'xl:sui-gap-8',
        configureContainerForWrap(cardCount, extraCardOnLargeAndAbove))}
      >
        {spotlightComponents}
      </div>
    </div>
  );

  const carouselContainer = (
    <div data-testid="spotlight-carousel-container">
      <Carousel
        title={title}
        breakpoints={configureContainerForCarousel(cardCount, extraCardOnLargeAndAbove)}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={null}
        hidePaginationOnOneOfOne
        disableMargin
      >
        {spotlightComponents}
      </Carousel>
    </div>
  );

  return (
    <>
      <ImpressionProvider
        key={componentPosition}
        data={{
          id: componentId,
          component: 'Section',
          name: 'Section',
          type: 'content'
        }}
      >
        {overflowBehavior === 'Wrap' ? wrapContainer : carouselContainer}
      </ImpressionProvider>
    </>
  );
};

SpotlightContainer.displayName = 'SpotlightContainer';
SpotlightContainer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  showDescription: PropTypes.bool,
  useB2bStyles: PropTypes.bool,
};

SpotlightContainer.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  showDescription: false,
  useB2bStyles: false,
};

SpotlightContainer.dataModel = extend({
  specializedContainer: alias('component').params({
    id: stringType()
      .isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['SpotlightContainer'])
      .isRequired(),
  }).shape({
    SpotlightContainer: namedFragment({
      inline: true,
      fragmentType: 'SpotlightContainer'
    }).shape({
      id: stringType(),
      title: stringType(),
      componentName: stringType(),
      overflowBehavior: boolType(),
      extraCardOnLargeAndAbove: boolType(),
      spotlightList: arrayOf(shape({
        ...Spotlight.dataModel.component
      })),
    }),
  }),
}, Spotlight);

export { SpotlightContainer };
