export const akamaiImageResize = (imageUrlToTransform) => {
  const queryParams = 'Resize=(703,395.44)';

  if (!imageUrlToTransform) {
    return imageUrlToTransform;
  }
  const isAkamaiHost = imageUrlToTransform.includes('thdstatic.com');
  if (!isAkamaiHost) {
    return imageUrlToTransform;
  }
  if (!imageUrlToTransform.includes('?')) {
    return `${imageUrlToTransform}?im=${queryParams}`;
  }
  if (imageUrlToTransform.includes('im=')) {
    const urlBeforeImParams = imageUrlToTransform.substring(
      0,
      imageUrlToTransform.indexOf('im=')
    );
    const imAndRemainderParams = imageUrlToTransform.substring(
      imageUrlToTransform.indexOf('im=')
    );
    const hasAdditionalParams = imAndRemainderParams.includes('&');
    if (hasAdditionalParams) {
      const remainderOfParams = imAndRemainderParams.substring(imAndRemainderParams.indexOf('&'));
      const imParams = imAndRemainderParams.substring(0, imAndRemainderParams.indexOf('&'));
      return `${urlBeforeImParams}${imParams};${queryParams}${remainderOfParams}`;
    }
    const imParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
    return `${urlBeforeImParams}${imParams};${queryParams}`;
  }
  return `${imageUrlToTransform}&im=${queryParams}`;
};
