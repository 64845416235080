/* eslint-disable */
import React from 'react';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { Link, Typography } from '@one-thd/sui-atomic-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const options = {
  preserveWhitespace: true,
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Typography key={`${text[0].substring(5)}-key`} variant="body-base" height="normal" weight="bold" component="span">
        {text}
      </Typography>
    ),
    [MARKS.UNDERLINE]: (text) => (
      <Typography
        key={`${text[0].substring(5)}-key`}
        variant="body-base"
        height="normal"
        weight="regular"
        decoration="underline"
        component="span"
      >
        {text}
      </Typography>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const isEmpty = node.content.every((child) => child.nodeType === 'text' && !child.value.trim());
      return isEmpty ? (
        <br />
      ) : (
        <Typography variant="body-base" height="normal" weight="regular" paragraph>
          {children}
        </Typography>
      );
    },
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="sui-pl-4 sui-list-disc">{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol className="sui-pl-4 sui-list-decimal">{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li>
        <Typography variant="body-base" height="normal" weight="regular" component="div">
          {children}
        </Typography>
      </li>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography key={`${children[0][0].substring(5)}-key`} variant="h1" height="loose" weight="regular" component="h1">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography key={`${children[0][0].substring(5)}-key`} variant="h2" height="loose" weight="regular" component="h2">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography key={`${children[0][0].substring(5)}-key`} variant="h3" height="normal" weight="regular" component="h3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography key={`${children[0][0].substring(5)}-key`} variant="h4" height="normal" weight="regular" component="h4">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography key={`${children[0][0].substring(5)}-key`} variant="h5" height="normal" weight="regular" component="h5">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography key={`${children[0][0].substring(5)}-key`} variant="h6" height="normal" weight="regular" component="h6">
        {children}
      </Typography>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link
        variant="body-base"
        height="normal"
        weight="regular"
        underline="always"
        target="_blank"
        color="subtle"
        component="a"
        href={node.data.uri}
      >
        {node.content[0].value}
      </Link>
    ),
  },
};

export const RichText = ({ content }) => {
  return documentToReactComponents(content, options);
};