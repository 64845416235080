import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography
} from '@one-thd/sui-atomic-components';

import { RichText } from './RichText';
import './content-accordion.scss';

const ContentAccordionRenderer = ({
  componentId, componentClass, descriptionContent, title, subtitle, componentName
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('content-accordion.ready'); }, []);

  return (
    <div className="content-accordion" data-component={componentClass}>
      <Accordion>
        <AccordionHeader aria-controls="accordion1-content" id="accordion1-header">
          <div className="sui-flex-auto sui-my-1 sui-block">
            <Typography
              component="div"
              variant="body-lg"
              weight="bold"
              height="tight"
            >
              {title}
            </Typography>
            {subtitle && (
              <div className="sui-mt-1">
                <Typography
                  variant="body-base"
                  height="tight"
                  color="subtle"
                >
                  {subtitle}
                </Typography>
              </div>
            )}
          </div>
        </AccordionHeader>
        <AccordionBody>
          <RichText content={descriptionContent} />
        </AccordionBody>
      </Accordion>
    </div>
  );
};

ContentAccordionRenderer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  descriptionContent: PropTypes.shape({}),
  componentName: PropTypes.string,
};

ContentAccordionRenderer.defaultProps = {
  title: '',
  subtitle: '',
  descriptionContent: null,
  componentName: 'ContentAccordion',
};

export { ContentAccordionRenderer };
