import {
  arrayOf, bool, number, shape, string
} from 'prop-types';
import classNames from 'classnames';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import * as constants from './constants';

const {
  isLiveGoods,
  isInStockYourStore,
  isInStockNearby,
  getNearbyStoreInStock,
  getSellableQuantityYourStore,
  getDeliveryZipCookie,
} = podFulFillmentUtils;

const {
  CONFIGURABLE_BLINDS,
  FULFILLMENT_METHOD_BOPIS,
  FULFILLMENT_METHOD_BODFS,
  FULFILLMENT_LOCATION_STH,
  FULFILLMENT_METHOD_STH,
  FULFILLMENT_METHOD_APPLIANCE,
  FULFILLMENT_METHOD_BOSS,
  MAJOR_APPLIANCE,
  MERCHANDISE,
  NON_RETURNABLE_MESSAGE,
  HOME_DEPOT_QUOTE_CENTER,
  HOME_DEPOT_DESIGN_CENTER
} = constants;

export const getReturnMessage = ({ showReturnable, product }) => {
  if (!showReturnable) return null;
  const returnable = product?.info?.returnable;
  if (/non-returnable/i.test(returnable)) return NON_RETURNABLE_MESSAGE;
  return null;
};

export const getAtcInfo = ({
  pageType = '',
  podType = '',
  position = 0,
  product = {}
}) => {
  let tempPageType = pageType;
  const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
  // Search pages (including redirect from browse)
  const nttParam = /Ntt-/.test(pathName);
  const isSearch = /\/s\//.test(pathName) || (/\/b\//.test(pathName) && nttParam);
  if (isSearch) {
    tempPageType = 'search results';
  }

  const productId = product?.itemId;
  const items = [];
  if (podType === 'sidebyside') {
    items.push({
      index: '',
      productId
    });
  } else {
    items.push({
      productId,
      index: position + 1
    });
  }

  const atcInfo = {
    items,
    pageType: tempPageType,
    sponsoredProduct: product?.info?.isSponsored
  };

  if (product?.info?.sponsoredBeacon?.onClickBeacon) {
    atcInfo.sponsoredClickBeacon = product.info.sponsoredBeacon.onClickBeacon;
    atcInfo.podPosition = position + 1;
  }

  return atcInfo;
};

export const getBopisLocation = (params) => {
  const { product = {}, store = {} } = params || {};
  if (isInStockNearby(product)) return getNearbyStoreInStock(product)?.locationId || '';
  return store?.storeId || '';
};

export const getSthLocation = ({ store }) => {
  return getDeliveryZipCookie()
    || store?.storeZip
    || FULFILLMENT_LOCATION_STH;
};

export const getFulfillmentLocation = (params) => {
  const { fulfillmentMethod = null, store = {}, product = {}, deliveryZipCode = null } = params || {};
  switch (fulfillmentMethod) {
  case FULFILLMENT_METHOD_STH:
    return getSthLocation({ store });
  case FULFILLMENT_METHOD_BOPIS:
    return getBopisLocation({ product, store });
  case FULFILLMENT_METHOD_BOSS:
    return store.storeId || '';
  case FULFILLMENT_METHOD_APPLIANCE:
  case FULFILLMENT_METHOD_BODFS:
    return deliveryZipCode || store.storeZip || '';
  default:
    return null;
  }
};

export const getHostName = () => {
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com' : origin;
  }
  return host;
};

export const getCartReqParams = ({
  store, quantity, deliveryZipCode, product, channel = 'desktop', noATCFulfillment, hasInStoreFilter, subscriptionInfo
}) => {
  const itemId = product?.identifiers?.itemId;
  const fulfillmentMethod = noATCFulfillment ? null
    : podFulFillmentUtils.getFulfillmentMethod(product, hasInStoreFilter);
  const fulfillmentLocation = noATCFulfillment ? null
    : getFulfillmentLocation({ fulfillmentMethod, store, product, deliveryZipCode });

  const { frequency, isOptIn } = subscriptionInfo || {};
  const subscriptionObj = {
    subscriptions: { frequency, ogModule: 'pdp_nocontent' }
  };

  if (fulfillmentMethod === FULFILLMENT_METHOD_BOPIS) {
    return {
      itemId,
      host: getHostName(),
      keyword: fulfillmentLocation,
      channel,
      quantity,
      alterBrowserHistory: true,
      isShipToHomeEligible: false,
      fulfillmentMethod,
      fulfillmentLocation
    };
  }

  return {
    itemId,
    quantity,
    fulfillmentMethod,
    fulfillmentLocation,
    ...(isOptIn && { ...subscriptionObj })
  };
};

export const getCartOptions = ({
  channel, hidden, sharedSection = '', misship
}) => {
  return {
    host: getHostName(),
    channel,
    paypal: false,
    misship,
    bypassBss: misship,
    bss: misship,
    hidden,
    sharedSection,
  };
};

export const getKeyProductFeatures = (features = [], maxToShow = 0) => {
  return features
    .slice(0, maxToShow)
    .map((feature) => `${feature.name} — ${feature.value}`);
};

export const productPodClasses = ({
  channel, className, hover, podType, mobilePodType
}) => {
  return classNames(channel, className, {
    'product-pod': !/horizontal|grouped|sidebyside/ig.test(podType),
    'grid horizontal-pod': podType === 'horizontal',
    'wrapped grouped-pod': podType === 'grouped',
    'grid grid--light-border': podType === 'sidebyside',
    'product-pod--hover-float': hover && channel === 'desktop',
    'product-pod__single-column-mobile': mobilePodType === 'singleColumn' && channel === 'mobile',
    'product-pod__double-column-mobile': mobilePodType === 'doubleColumn' && channel === 'mobile'
  });
};

export const isEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const isGenericProduct = (product) => {
  return !!(product?.info?.isGenericProduct);
};

export const isLiveGoodsOOSProduct = (product) => {
  return (isLiveGoods(product) && !getSellableQuantityYourStore(product));
};

export const isSellableAtSelectedStore = ({ product }) => {
  return (getSellableQuantityYourStore(product) > 0);
};

export const isBuildAndBuyProduct = (product, hover) => {
  const { info, identifiers } = product || {};

  /* TODO: Using hover to handle the differences between hover (hd home) and GM.
    This is temporary and should be fixed once hd home aligns with GMs way of doing this.
    If the decision is made not to solve the difference in the logic, this todo should be removed. */

  return hover ? info?.globalCustomConfigurator?.customExperience
  && identifiers?.productType === CONFIGURABLE_BLINDS
    : identifiers?.productType === CONFIGURABLE_BLINDS;
};

const isCustomCabinet = (productSubType) => {
  const { link = '' } = productSubType || {};
  const checkCustomCabinet = /BRIO_INAISLE_SFI_CABINETS/.test(link);
  return checkCustomCabinet;
};

export const isBrioProduct = (product) => {
  const { info } = product || {};
  const { isBrioSku, isCustomKitchenCabinet, productSubType } = info || {};
  return (isBrioSku && !(isCustomCabinet(productSubType) || isCustomKitchenCabinet));
};

export const isCustomKitchenCabinetProduct = (product) => {
  const { info } = product || {};
  const { isBrioSku, isCustomKitchenCabinet, productSubType } = info || {};
  return (isBrioSku && (isCustomCabinet(productSubType) || isCustomKitchenCabinet));
};

export const isHidePriceSKU = (product) => {
  return !!(product?.info?.hidePrice);
};

export const checkIsHDQCSku = (product) => {
  return product?.info?.productSubType?.name === HOME_DEPOT_QUOTE_CENTER;
};

export const checkIsHDDCSku = (product) => {
  return product?.details?.installation?.serviceType === HOME_DEPOT_DESIGN_CENTER
    || product?.info?.productSubType?.name === HOME_DEPOT_DESIGN_CENTER;
};

export const checkScheduleAMeasureEligibility = (product) => {
  return !!(product?.installServices?.scheduleAMeasure);
};

export const checkGccCarpetDesignAndOrderEligibility = (product) => {
  return !!(product?.installServices?.gccCarpetDesignAndOrderEligible);
};

export const getGccCarpetDesignAndOrderURL = (product) => {
  const { itemId } = product || {};
  return `https://custom.homedepot.com/flooring/${itemId}`;
};

export const getMisship = (params) => {
  const { product = {} } = params || {};
  return !!(isInStockYourStore(product));
};

export const getShowBopisOverlay = (params) => {
  const {
    product = {}, cartReqParams = {}
  } = params || {};

  const fulfillmentMethod = cartReqParams?.fulfillmentMethod || '';
  let showBopisOverlay = false;

  if (fulfillmentMethod === FULFILLMENT_METHOD_BOPIS) {
    showBopisOverlay = true;

    if (isInStockYourStore(product)) {
      showBopisOverlay = false;
    }
  }

  return showBopisOverlay;
};

export const isProductOutOfStockOnline = (product = {}) => {
  const isBODFS = podFulFillmentUtils.isBODFS(product);
  const isBOPIS = podFulFillmentUtils.isBOPIS(product);
  const isOutOfStock = podFulFillmentUtils.isOutOfStockOnline(product);
  const isOOS = isOutOfStock && !(isBOPIS || isBODFS);
  return isOOS;
};

export const isATCEnabled = (product) => {
  const isBODFS = podFulFillmentUtils.isBODFS(product);
  const isBOPIS = podFulFillmentUtils.isBOPIS(product)
    && (podFulFillmentUtils.isInStockYourStore(product) || podFulFillmentUtils.isInStockNearby(product));
  const isBrowseOnly = podFulFillmentUtils.isBrowseOnly(product);
  const isCurrentlyUnavailable = podFulFillmentUtils.isCurrentlyUnavailable(product);
  const isDiscontinued = podFulFillmentUtils.isDiscontinued(product);
  const isOutOfStock = podFulFillmentUtils.isOutOfStockOnline(product);
  const isSTH = podFulFillmentUtils.isSTH(product) && !podFulFillmentUtils.getExcludedStateSth(product);
  const isBOSS = podFulFillmentUtils.isBOSS(product)
    && !podFulFillmentUtils.isOutOfStockBoss(product)
    && !podFulFillmentUtils.getExcludedStateBoss(product);
  const isFulfillable = podFulFillmentUtils.isFulfillable(product);
  const isLimitedStock = podFulFillmentUtils.isLimitedStock(product);
  const isLimitedStockNearby = podFulFillmentUtils.isLimitedStockNearby(product);
  const isBuyInStore = podFulFillmentUtils.isBuyInStore(product);
  // IRG fulfillment data comes in differently,
  // According to the business we need to show atc always so no need below check.
  // const isIRGFulfillable = podFulFillmentUtils.isFulfillableIRGSku(product);

  const isBODFSUnavaialble = (isBODFS && !isSTH && isCurrentlyUnavailable);
  const notAvailInStore = isBrowseOnly && !isBODFS;
  const isOOS = isProductOutOfStockOnline(product);
  const isHidePrice = isHidePriceSKU(product);
  const isLimitedStockNearbyWithNoSTH = isLimitedStockNearby && (isOOS || (!isSTH && !isBODFS));
  const isLimitedStockWithNoSTH = isLimitedStock && (isOOS || (!isSTH && !isBODFS));
  const noFulfillment = !isBODFS && !isSTH && !isBOPIS && !isBOSS;
  const onlyBuyInStore = isBuyInStore && !isSTH && !isBODFS;

  return !(isDiscontinued
    || isOOS
    || isBODFSUnavaialble
    || notAvailInStore
    || !isFulfillable
    || isHidePrice
    || noFulfillment
    || isLimitedStockNearbyWithNoSTH
    || isLimitedStockWithNoSTH
    || onlyBuyInStore
    || isCurrentlyUnavailable
  );
};

export const primaryImageClass = ({ secondaryimageUrl }) => {
  return classNames({ 'product-pod--has-secondary-image': secondaryimageUrl });
};

export const updateImageSrc = ({ info = {}, size = '400' }) => {
  let path = info.imageUrl ? info.imageUrl : '';
  if (path) {
    return path.replace(/<SIZE>/g, size);
  }
  return '';
};

// Removing variant from the group if it has single option.
// @TODO: What are options in new schema/api?
export const getFilteredVariants = ({ variants = [] }) => {
  return variants.filter((variant) => variant.options && variant.options.length > 1);
};

export const getUnselectedVariantNames = ({ swatches = [] }) => {
  const unselected = swatches
    .filter((swatch) => (!swatch.isSelected))
    .map((swatch) => swatch.label);

  const lastSwatch = unselected.pop() || '';

  return unselected.length > 0
    ? `${unselected.join(', ')} and ${lastSwatch}`
    : lastSwatch;
};

export const badgeShape = shape({
  name: string,
  message: string,
  label: string
});

export const productShape = shape({
  details: shape({
    collection: shape({
      name: string,
      url: string,
      type: string
    })
  }),
  media: {
    images: arrayOf(shape({
      subType: string,
      url: string,
    })),
  },
  identifiers: shape({
    parentId: string,
    itemId: string,
    productLabel: string,
    canonicalUrl: string,
    productUrl: string,
    specialOrderSku: string,
    storeSkuNumber: string
  }),
  badges: arrayOf(badgeShape),
  availabilityType: shape({
    discontinued: bool
  }),
  pricing: shape({
    value: number
  }),
  reviews: shape({
    ratingsReviews: shape({
      averageRating: string,
      totalReviews: string
    }),
  })
});

export const productDefaults = {
  details: {
    collection: {
      name: null,
      url: null,
      type: null
    }
  },
  media: {
    images: null
  },
  identifiers: {
    itemId: null,
    productLabel: null,
    canonicalUrl: null,
    productUrl: null,
    specialOrderSku: string,
    storeSkuNumber: string
  },
  badges: null,
  availabilityType: {
    discontinued: null
  },
  pricing: {
    value: null
  },
  reviews: {
    ratingsReviews: {
      averageRating: null,
      totalReviews: null
    },
  }
};

export const getCustomBlindsUrl = ({
  customExperience,
  productType,
  blindsHost,
  canonicalUrl,
  hover
}) => {
  const configureProduct = productType === CONFIGURABLE_BLINDS;
  const regex = /\/p\//gi;
  const customBase = customExperience ? `/p/${customExperience}/` : '/p/custom-blinds/';
  const customProductUrl = canonicalUrl && canonicalUrl.replace(regex, customBase);
  if (hover) {
    return (customExperience && configureProduct)
      ? `${blindsHost}${customProductUrl}`
      : canonicalUrl;
  }
  return (configureProduct) ? `${blindsHost}${customProductUrl}` : canonicalUrl;
};

export const getCustomUrlWithAnalytics = ({
  customExperience,
  productType,
  blindsHost,
  canonicalUrl,
  hover
}) => {
  return getCustomBlindsUrl({
    customExperience, productType, blindsHost, canonicalUrl, hover
  });
};

export const shouldShowQuantityBox = (product) => {
  const { identifiers } = product || {};
  const { productType } = identifiers || {};
  return isATCEnabled(product) && !isGenericProduct(product) && productType === MERCHANDISE;
};

export const getModifiedBadges = ({ badgesProp, badge }) => {
  return (badgesProp?.length !== 0 ? [...badgesProp, badge] : [badge]);
};

export const getServicesFormName = (servicesURL = '') => {
  const urlParts = servicesURL.split('/form')?.[0]?.split('/');
  return urlParts?.[urlParts?.length - 1]?.toLowerCase();
};

export const isAppliance = (product = {}) => {
  return !!(product?.identifiers?.productType?.toUpperCase() === MAJOR_APPLIANCE);
};
