import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Close } from '../private/icons/Close';
import { ArrowBack } from '../private/icons/ArrowBack';
import { IconButton } from '../button/IconButton';
import { DrawerTitle } from './DrawerTitle';

/**
 * Indicates the primary message or task to complete within the drawer.
 * Can containt start and end addorments to help with multi-stage navigation.
 *
 * Related components: [Drawer](#drawer), [DrawerTitle](#drawertitle), [Button](#button), [IconButton](#iconbutton)
 *
 * Usage:
 *
 * ```jsx
 * import { DrawerHeader } from '@one-thd/sui-atomic-components';
 * ```
 */
const DrawerHeader = React.forwardRef((props, ref) => {

  const {
    children,
    endAdornment: endAdornmentProp,
    onBack,
    onClose,
    startAdornment: startAdornmentProp,
    title,
    ...other
  } = props;

  const startAdornment = onBack ? (
    <IconButton
      aria-label="back"
      onClick={onBack}
      icon={ArrowBack}
    />
  ) : startAdornmentProp;

  const endAdornment = onClose ? (
    <IconButton
      aria-label="close"
      onClick={onClose}
      icon={Close}
    />
  ) : endAdornmentProp;

  const hasStartAndEndAdornment = Boolean(startAdornment && endAdornment);

  return (
    <div
      className="sui-flex sui-flex-col sui-px-2 sui-py-2 sui-bg-primary"
      ref={ref}
      {...other}
    >
      <div className="sui-flex sui-items-center">
        {startAdornment != null ? (
          <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto">
            {startAdornment}
          </div>
        ) : null}
        <div
          className={classNames('sui-shrink sui-basis-auto', {
            'sui-grow-0 sui-mx-auto': hasStartAndEndAdornment,
            'sui-grow': !hasStartAndEndAdornment
          })}
        >
          {title != null ? (
            <DrawerTitle disableGutters={hasStartAndEndAdornment}>
              {title}
            </DrawerTitle>
          ) : null}
        </div>
        {endAdornment != null ? (
          <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto">
            {endAdornment}
          </div>
        ) : null}
      </div>
      {children ? (
        <div className="sui-flex-auto sui-pt-1">{children}</div>
      ) : null}
    </div>
  );
});

DrawerHeader.displayName = 'DrawerHeader';

DrawerHeader.propTypes = {
  /**
   * DrawerHeader children, usually DrawerTitle.
   */
  children: PropTypes.node,
  /**
   * Function to handle a close event for drawer.
   */
  onClose: PropTypes.func,
  /**
   * Function to handle the back event for nested drawer.
   */
  onBack: PropTypes.func,
  /**
   * End icons for this component.
   */
  endAdornment: PropTypes.node,
  /**
   * Start icon for this component.
   */
  startAdornment: PropTypes.node,
  /**
   * The title of the drawer header.
   */
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

DrawerHeader.defaultProps = {
};

export { DrawerHeader };
