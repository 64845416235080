/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Popover
} from '@one-thd/sui-atomic-components';
import { Image } from '@thd-olt-component-react/core-ui';
import helpers, { CLEARANCE_MESSAGE } from '../../helpers/price-helper';
import { ClearancePopoverContent } from './ClearancePopoverContent';
import '../../styles/price-stencil-override.scss';

export const ClearancePricingBadge = ({
  storeName, originalPrice, clearanceValue, dollarOff, percentageOff, sku, unitsClearancePrice, caseUnitOfMeasure,
  unitsOriginalPrice, alternatePriceDisplay
}) => {

  const CLEARANCE_POPOVER_MESSAGE = `Visit ${storeName} to purchase this item on Clearance in store.`
   + ' While supplies last.';
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const badgeSizeWidth = 32;
  const badgeSizeHeight = 16;

  useEffect(() => {
    helpers.setClearanceAnalytics('badge', {
      recommendationStrategy: 'n/a',
      recommendationVersion: 'n/a',
      sku
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    helpers.setClearanceAnalytics('tooltipEvent', {
      primaryCategory: 'tooltip',
      eventName: 'in-store clearance message',
      sku
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        placement="bottom"
        title="In-Store Clearance Item"
        onClose={handleClose}
        style={{ zIndex: 900 }}
      >
        <ClearancePopoverContent
          message={CLEARANCE_POPOVER_MESSAGE}
          originalPrice={originalPrice}
          clearanceValue={clearanceValue}
          dollarOff={dollarOff}
          percentageOff={percentageOff}
          unitsClearancePrice={unitsClearancePrice}
          caseUnitOfMeasure={caseUnitOfMeasure}
          unitsOriginalPrice={unitsOriginalPrice}
          alternatePriceDisplay={alternatePriceDisplay}
        />
      </Popover>
      <div className="sui-flex sui-pb-3 clearance-badge-link">
        <span className="sui-pr-2 sui-pt-[4px] sui-pl-[5px] hover:sui-cursor-pointer">
          <Image
            src="https://assets.thdstatic.com/images/v1/Value-Pricing-Clearance.svg"
            width={badgeSizeWidth}
            height={badgeSizeHeight}
            alt="Clearance Badge"
            title="In-Store Clearance Pricing"
            onClick={(event) => handleClick(event)}
          />
        </span>
        <Typography
          component="span"
          role="button"
          onClick={(event) => handleClick(event)}
        >
          <span className="sui-z-10 clearance-price-link">
            {CLEARANCE_MESSAGE}
          </span>
        </Typography>
      </div>
    </>

  );
};

ClearancePricingBadge.propTypes = {
  storeName: PropTypes.string.isRequired,
  originalPrice: PropTypes.number.isRequired,
  clearanceValue: PropTypes.number.isRequired,
  dollarOff: PropTypes.number.isRequired,
  percentageOff: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  unitsClearancePrice: PropTypes.number.isRequired,
  unitsOriginalPrice: PropTypes.number.isRequired,
  caseUnitOfMeasure: PropTypes.string.isRequired,
  alternatePriceDisplay: PropTypes.bool
};

ClearancePricingBadge.defaultProps = { alternatePriceDisplay: false };
