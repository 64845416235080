import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade } from '../private/components/fade/Fade';
import { ModalBase } from '../modal/ModalBase';
import { SwipeableDrawer } from './SwipeableDrawer';
import { PositionedDrawer } from './PositionedDrawer';
import { useBreakpoint } from '../hooks/useBreakpoint';
import HTMLElementType from '../private/utils/HTMLElementType';

const DrawerRoot = React.forwardRef((props, ref) => {

  const {
    children,
    fixed,
    position,
    variant = 'positioned',
    ...other
  } = props;

  return (
    <>
      {variant === 'positioned' && (
        <PositionedDrawer
          ref={ref}
          position={position}
          {...other}
        >
          {children}
        </PositionedDrawer>
      )}
      {variant === 'swipeable' && (
        <SwipeableDrawer
          ref={ref}
          fixed={fixed}
          {...other}
        >
          {children}
        </SwipeableDrawer>
      )}
    </>
  );
});

/**
 * `Drawer`s are containers used to display supplementary information.
 * A `Drawer` sits above the main page anchored to either the bottom, left or right of a browser window,
 * based on its context.
 *
 * Use `Drawer` with the following sub-components: [DrawerHeader](#drawerheader), [DrawerTitle](#drawertitle),
 * [DrawerBody](#drawerbody), [DrawerFooter](#drawerfooter)
 *
 * Usage:
 *
 * ```jsx
 * import { Drawer } from '@one-thd/sui-atomic-components';
 * ```
 */
const Drawer = React.forwardRef((props, ref) => {

  const {
    children,
    container,
    DrawerRootProps = {},
    fixed = false,
    position = 'right',
    open = false,
    onClose,
    ...other
  } = props;

  const screen = useBreakpoint('md', { current: true });
  const isSmall = screen.current === 'unprefixed';

  const gridClasses = classNames('sui-h-full sui-outline-none', {
    'sui-grid sui-grid-cols-12 sui-gap-4': !isSmall
  });

  return (
    <ModalBase
      open={open}
      onClose={onClose}
      ref={ref}
      container={container}
      {...other}
    >
      <Fade in={open} classes={gridClasses}>
        <DrawerRoot
          fixed={fixed}
          position={position}
          variant={isSmall ? 'swipeable' : 'positioned'}
          {...DrawerRootProps}
        >
          {children}
        </DrawerRoot>
      </Fade>
    </ModalBase>
  );
});

Drawer.displayName = 'Drawer';

Drawer.propTypes = {
  /**
   * @ignore
   */
  container: PropTypes.oneOfType([
    HTMLElementType,
    PropTypes.func,
  ]),
  /**
   * Drawer children, usually the included sub-components.
   */
  children: PropTypes.node,
  /**
   * Props applied to container component, either PositionedDrawer or SwipeableDrawer.
   * @default {}
   */
  DrawerRootProps: PropTypes.object,
  /**
   * If true, the drawer is shown.
   * @default false
   */
  open: PropTypes.bool.isRequired,
  /**
   * Function to handle a close event for drawer.
   */
  onClose: PropTypes.func,
  /**
   * Placement of the drawer.
   * @default 'right'
   */
  position: PropTypes.oneOf(['left', 'right']),
  /**
   * Prop passed to SwipeableDrawer and adds a max-height 50%, min-height 50%, and height 50% to drawer.
   * @default false
   */
  fixed: PropTypes.bool
};

Drawer.defaultProps = {};

export { Drawer };