import React, { useCallback, useState } from 'react';
import { arrayOf, node, shape, string } from 'prop-types';
import classNames from 'classnames/bind';

import { QueryProvider, extend } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { Carousel } from '@one-thd/sui-carousel';
import { ProductPodPlaceholder } from '../ProductPodPlaceholders/ProductPodPlaceholder';
import { SpecialBuyBannerProductPod } from '../SpecialBuyBannerProductPod/SpecialBuyBannerProductPod';
import { SeeAll } from './subcomponents/SeeAll';
import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import { useSearchProducts } from '../../hooks/useSearchProducts';
import { MAX_NUM_ITEMS_TO_LOAD } from '../../constants';

const HoistingWrapper = ({ children }) => <>{children}</>;
HoistingWrapper.propTypes = { children: node.isRequired };
HoistingWrapper.dataModel = extend(SpecialBuyBannerProductPod);

const optionsHandler = ({ options }) => {
  return {
    ...options,
    fetchPolicy: 'cache-first'
  };
};

export const CategoryProducts = ({ category }) => {
  const { selectedCategory, simpleBanner } = useSpecialBuy();
  const { storeId, storeZip } = useStore();
  const [isFetchAllItemIds, setIsFetchAllItemIds] = useState(false);
  const fetchAllItems = useCallback(() => setIsFetchAllItemIds(true), [setIsFetchAllItemIds]);
  const isSelected = Object.is(selectedCategory, category);

  const {
    products,
    itemIdsToDisplay,
    isLoading,
  } = useSearchProducts(category, isFetchAllItemIds);

  const itemIdsWithSwatches = new Set([
    ...products.filter((product) => Boolean(product?.info?.swatches?.length)),
  ]);

  const productSlides = [];
  const placeholderSlides = [];
  const seeAllSlide = [];

  // first make the product pods that we have data for already (itemIdsToDisplay)
  itemIdsToDisplay.forEach((itemId, index) => {
    productSlides.push(
      <SpecialBuyBannerProductPod
        itemId={itemId}
        key={itemId}
        position={index}
        shouldHideSeeMoreOptions={!itemIdsWithSwatches.has(itemId)}
      />
    );
  });

  // push placeholders if not done loading
  const shouldShowPlaceholders = !isFetchAllItemIds || isLoading;
  if (shouldShowPlaceholders) {
    const totalNumPods = Math.min(category.itemIds.length, MAX_NUM_ITEMS_TO_LOAD);
    category.itemIds.slice(itemIdsToDisplay.length, totalNumPods).forEach((itemId) => {
      placeholderSlides.push(<ProductPodPlaceholder key={`placeholder-pod-${itemId}`} />);
    });
  }

  const shouldShowSeeAll = category.itemIds.length > MAX_NUM_ITEMS_TO_LOAD && !simpleBanner;
  if (shouldShowSeeAll) {
    seeAllSlide.push(<SeeAll category={category} key={`see-all-${category.categoryId}`} />);
  }

  const categoryClassName = classNames({
    'sui-hidden': !isSelected,
  });

  const defaultVariables = {
    storeId,
    zipCode: storeZip,
  };

  return (
    <article className={categoryClassName} data-component="CategoryProducts">
      <QueryProvider
        cacheKey="special-buy-carousel"
        dataSource="searchNav"
        defaultVariables={defaultVariables}
        queryOptions={optionsHandler}
      >
        <HoistingWrapper>
          <Carousel
            SwiperProps={{ onSlideChange: fetchAllItems }}
          >
            {[
              ...productSlides,
              ...placeholderSlides,
              ...seeAllSlide,
            ]}
          </Carousel>
        </HoistingWrapper>
      </QueryProvider>
    </article>
  );
};

CategoryProducts.displayName = 'CategoryProducts';

CategoryProducts.propTypes = {
  category: shape({
    name: string.isRequired,
    itemIds: arrayOf(string.isRequired).isRequired,
    categoryId: string.isRequired,
  }).isRequired
};

CategoryProducts.dataModel = extend(HoistingWrapper);
