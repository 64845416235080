import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '../typography/Typography';

/**
 * `AccordionBody` houses the content to be expanded or collapsed in [Accordion](#accordion)
 *
 * Use it along with [AccordionHeader](#accordionheader)
 *
 * Usage:
 *
 * ```jsx
 * import { AccordionBody } from '@one-thd/sui-atomic-components';
 * ```
 */
const AccordionBody = React.forwardRef((props, ref) => {
  const { children: childrenProp, ...other } = props;
  let children;
  if (typeof childrenProp === 'string') {
    children = <Typography>{childrenProp}</Typography>;
  } else {
    children = childrenProp;
  }

  return (
    <div
      ref={ref}
      className="sui-px-4 sui-py-4 sui-border-solid sui-border-b-1 sui-border-x-1 sui-border-primary sui-rounded-base"
      {...other}
    >
      {children}
    </div>
  );
});

AccordionBody.displayName = 'AccordionBody';

AccordionBody.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
};

export { AccordionBody };
