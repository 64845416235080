import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '../typography/Typography';
import { Eyebrow } from './Eyebrow';

const defaultVariantMap = {
  h3: {
    variant: 'h3',
    weight: 'display',
    lineClamp: '3'
  },
  h5: {
    variant: 'h5',
    weight: 'display',
    lineClamp: '3'
  }
};

/**
 * Contains the header and subheader of a `Card` component, along with optional eyebrow and action components.
 *
 * `header` and `subheader` are by default wrapped by a Typography component for standardized typography and for
 * ease of use, allowing you to pass plain string text as prop values. There are mutliple ways to override this
 * behavior.
 *
 * - Use the `headerVariant` prop to swap the default heading typography from h3 to h5 standardized typography.
 *  This will only apply to the header, not the subheader.
 * - Pass an alternative `Typography` component variant as prop values to header and/or subheader.
 * - Set `disableTypography` as `true` to wrap the header text, and optional subheader text with your own custom
 * typography.
 *
 * Related components: [Card](#card), [Typography](#typography)
 *
 * Usage:
 *
 * ```jsx
 * import { CardTitle } from '@one-thd/sui-atomic-components';
 * ```
 */
const CardTitle = React.forwardRef((props, ref) => {

  const {
    action: actionProp,
    actionPosition = 'start',
    component: CardTitleRoot = 'div',
    disableActionSpacing = false,
    padding = false,
    grow = false,
    disableTypography = false,
    eyebrow: eyebrowProp,
    eyebrowOrnament,
    header: headerProp,
    headerVariant = 'h3',
    leadingIcon: leadingIconProp,
    subheader: subheaderProp,
    ...other
  } = props;

  let eyebrow = eyebrowProp;
  if (eyebrow != null || eyebrowOrnament != null) {
    eyebrow = (<Eyebrow title={eyebrow} ornament={eyebrowOrnament} />);
  }

  let leadingIcon = leadingIconProp && (
    <span className="sui-flex sui-mr-2">
      {leadingIconProp}
    </span>
  );

  let header = headerProp;
  if (header != null && header.type !== Typography && !disableTypography) {
    const headerTypographyProps = defaultVariantMap[headerVariant];
    header = (
      <div className="sui-flex sui-items-center">
        {leadingIcon}
        <Typography {...headerTypographyProps}>{header}</Typography>
      </div>
    );
  }

  let subheader = subheaderProp;
  if (subheader != null && subheader.type !== Typography && !disableTypography) {
    subheader = (<Typography color="subtle">{subheader}</Typography>);
  }

  let action = actionProp;
  if (action != null) {
    const actionClasses = classNames('sui-grow-0 sui-shrink-0 sui-basis-auto', {
      'sui-self-start': actionPosition === 'start',
      'sui-self-center': actionPosition === 'center',
      'sui-self-end': actionPosition === 'end',
      'sui--mr-2': !disableActionSpacing
    });
    action = (
      <div className={actionClasses}>
        {action}
      </div>
    );
  }

  const rootClasses = classNames('sui-flex sui-items-center', {
    'sui-p-4': padding,
    'sui-grow': grow
  });

  return (
    <CardTitleRoot
      className={rootClasses}
      ref={ref}
      {...other}
    >
      <div className="sui-flex-auto sui-self-start">
        {eyebrow}
        {header}
        {subheader}
      </div>
      {action}
    </CardTitleRoot>
  );
});

CardTitle.displayName = 'CardTitle';

CardTitle.propTypes = {
  /**
   * The action ornament, button or link of the title
   */
  action: PropTypes.node,
  /**
   * The position of the action.
   * @default 'start'
   */
  actionPosition: PropTypes.PropTypes.oneOf(['start', 'center', 'end']),
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, `subheader` and `title` won't be wrapped by a Typography component.
   * @default false
   */
  disableTypography: PropTypes.bool,
  /**
   * If true, the actions do not have additional margin.
   * @default false
   */
  disableActionSpacing: PropTypes.bool,
  /**
   * The contents of the eyebrow.
   */
  eyebrow: PropTypes.string,
  /**
   * The leading ornament within the eyebrow.
   */
  eyebrowOrnament: PropTypes.node,
  /**
   * The contents of the header.
   */
  header: PropTypes.node,
  /**
   * The icon to display before the header.
   */
  leadingIcon: PropTypes.node,
  /**
   * The typography variant to use in header. Either `h3` or `h5`
   * h3: { height: 'snug',  weight: 'display', lineClamp: '3' }
   * h5: { height: 'snug',  weight: 'display', lineClamp: '3' }
   * @default 'h3'
   */
  headerVariant: PropTypes.oneOf(['h3', 'h5']),
  /**
   * If `true`, the Title will grow to take up available space
   * @default false
   */
  grow: PropTypes.bool,
  /**
   * If `true`, the padding is added.
   * @default false
   */
  padding: PropTypes.bool,
  /**
   * The contents of the subheader.
   */
  subheader: PropTypes.node
};

CardTitle.defaultProps = {};

export { CardTitle };