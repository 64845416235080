import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardMedia,
  CardTitle,
  Typography
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { string, number, bool as boolean, shape as shapeType } from 'prop-types';
import {
  params,
  shape,
  customType,
  typename,
  arrayOf,
  namedFragment,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import classNames from 'classnames/bind';
import VideoPlayerWrap from './VideoPlayerWrap';
import styles from './resource.scss';

const Resource = ({
  componentId, componentClass, componentPosition, boldTitle, lazyLoad, livePreviewData
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('resource.ready'); }, []);

  const cx = classNames.bind(styles);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: Resource.displayName,
      component: Resource.displayName,
      position: componentPosition,
      type: 'content',
    }
  });

  let dataComponent = data?.component;
  if (livePreviewData) {
    dataComponent = livePreviewData;
  }

  let {
    title,
    altText,
    link,
    description,
    thumbNailImage: thumb,
    eyebrow,
    previewImage,
    video,
  } = dataComponent || {};

  const { url } = data?.component?.image?.damDownloadedContent || {};
  let imageUrl = thumb || url;

  const akamaiImageResize = (imageUrlToTransform) => {
    const queryParams = 'Resize=(703,395.44)';

    if (!imageUrlToTransform) {
      return imageUrlToTransform;
    }
    const isAkamaiHost = imageUrlToTransform.includes('thdstatic.com');
    if (!isAkamaiHost) {
      return imageUrlToTransform;
    }
    if (!imageUrlToTransform.includes('?')) {
      return `${imageUrlToTransform}?im=${queryParams}`;
    }
    if (imageUrlToTransform.includes('im=')) {
      const urlBeforeImParams = imageUrlToTransform.substring(0, imageUrlToTransform.indexOf('im='));
      const imAndRemainderParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
      const hasAdditionalParams = imAndRemainderParams.includes('&');
      if (hasAdditionalParams) {
        const remainderOfParams = imAndRemainderParams.substring(imAndRemainderParams.indexOf('&'));
        const imParams = imAndRemainderParams.substring(0, imAndRemainderParams.indexOf('&'));
        return `${urlBeforeImParams}${imParams};${queryParams}${remainderOfParams}`;
      }
      const imParams = imageUrlToTransform.substring(imageUrlToTransform.indexOf('im='));
      return `${urlBeforeImParams}${imParams};${queryParams}`;
    }
    return `${imageUrlToTransform}&im=${queryParams}`;
  };

  if (previewImage) {
    const damDownloadedContentImage = previewImage.damDownloadedContent;
    const damContentSelectorImage = previewImage.damContentSelector?.assetData?.[0];

    if (damDownloadedContentImage?.url) {
      imageUrl = damDownloadedContentImage.url;
    } else {
      imageUrl = damContentSelectorImage?.selectedImageUrl || imageUrl;
    }
  }

  imageUrl = akamaiImageResize(imageUrl);
  return (
    <div
      data-component-id={componentId}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component-name="Resource"
      data-component-position={componentPosition}
      data-component="Resource"
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={cx('resource--grid sui-grid link--hover-no-underline')}
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <Card className="sui-w-full resource-card">
        {(video) ? (
          <div data-contentful-entry-id={componentId} data-contentful-field-id="video">
            <VideoPlayerWrap videoUrl={video} />
          </div>
        ) : (
          <CardMedia
            src={imageUrl}
            aspect="wide"
            alt={altText || title}
            loading={lazyLoad ? 'lazy' : ''}
            data-contentful-entry-id={componentId}
            data-contentful-field-id="previewImage"
          />
        )}
        <CardTitle
          eyebrow={eyebrow}
          header={title}
          data-contentful-entry-id={componentId}
          data-contentful-field-id="eyebrow"
        />
        <CardBody>
          <Typography
            height="normal"
            weight="regular"
            variant="body-base"
            color="primary"
            data-contentful-entry-id={componentId}
            data-contentful-field-id="description"
          >{description}
          </Typography>
        </CardBody>
        {

          link && (
            <Button
              href={link}
              variant="text"
              aria-label="learnMore"
              data-contentful-entry-id={componentId}
              data-contentful-field-id="link"
            >Learn More
            </Button>
          )
        }

      </Card>
    </div>
  );
};

Resource.displayName = 'Resource';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

Resource.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  boldTitle: boolean,
  lazyLoad: boolean,
  livePreviewData: shapeType({
    id: string,
    title: string,
    altText: string,
    link: string,
    thumbNailImage: string,
    image: shapeType({
      damDownloadedContent: shapeType({
        url: string
      })
    }),
    video: string,
    previewImage: DamMediaFragment,
    description: string,
    eyebrow: string
  }),
};

Resource.defaultProps = {
  componentPosition: 1,
  componentClass: '',
  boldTitle: false,
  lazyLoad: false,
  livePreviewData: null,
};

Resource.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['Resource'])
      .isRequired()
  }).shape({
    Resource: namedFragment({
      inline: true,
      fragmentType: 'Resource'
    }).shape({
      id: stringType(),
      title: stringType(),
      altText: stringType(),
      link: stringType(),
      thumbNailImage: stringType(),
      image: shape({
        damDownloadedContent: shape({
          url: stringType()
        })
      }),
      video: stringType(),
      previewImage: DamMediaFragment,
      description: stringType(),
      eyebrow: stringType()
    }),
  }),
};

export { Resource };
