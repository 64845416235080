import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import {
  FULFILLMENT_METHOD_BOPIS,
  FULFILLMENT_METHOD_STH,
  FULFILLMENT_METHOD_BOSS,
  FULFILLMENT_METHOD_APPLIANCE,
  FULFILLMENT_METHOD_BODFS,
  FULFILLMENT_LOCATION_STH,
  MAJOR_APPLIANCE
} from '../../constants';

const {
  isInStockYourStore, isInStockNearby, getFulfillmentMethod, getNearbyStoreInStock, getDeliveryZipCookie
} = podFulFillmentUtils;

const getHostName = () => {
  let host = 'https://www.homedepot.com';
  if (typeof window !== 'undefined') {
    const origin = window.location.origin;
    host = origin.match(/local/g) ? 'https://www.homedepot.com' : origin;
  }
  return host;
};

const getSthLocation = ({ store }) => {
  return getDeliveryZipCookie()
      || store?.storeZip
      || FULFILLMENT_LOCATION_STH;
};

const getBopisLocation = ({ product = {}, store = {} }) => {
  if (isInStockNearby(product)) return getNearbyStoreInStock(product)?.locationId || '';
  return store?.storeId || '';
};

export const getMisship = (params) => {
  const { product = {} } = params || {};
  return !!(isInStockYourStore(product));
};

export const getShowBopisOverlay = (params) => {
  const {
    product = {}, cartReqParams = {}
  } = params || {};

  const fulfillmentMethod = cartReqParams?.fulfillmentMethod || '';
  let showBopisOverlay = false;

  if (fulfillmentMethod === FULFILLMENT_METHOD_BOPIS) {
    showBopisOverlay = true;

    if (isInStockYourStore(product)) {
      showBopisOverlay = false;
    }
  }

  return showBopisOverlay;
};

export const isAppliance = (product = {}) => {
  return !!(product?.identifiers?.productType?.toUpperCase() === MAJOR_APPLIANCE);
};

export const getFulfillmentLocation = (params) => {
  const { fulfillmentMethod = null, store = {}, product = {}, deliveryZipCode = null } = params || {};
  switch (fulfillmentMethod) {
  case FULFILLMENT_METHOD_STH:
    return getSthLocation({ store });
  case FULFILLMENT_METHOD_BOPIS:
    return getBopisLocation({ product, store });
  case FULFILLMENT_METHOD_BOSS:
    return store.storeId || '';
  case FULFILLMENT_METHOD_APPLIANCE:
  case FULFILLMENT_METHOD_BODFS:
    return deliveryZipCode || store.storeZip || '';
  default:
    return null;
  }
};

export const getCartReqParams = ({
  store, quantity, deliveryZipCode, product, channel = 'desktop', noATCFulfillment, hasInStoreFilter, subscriptionInfo
}) => {
  const itemId = product?.identifiers?.itemId;
  const fulfillmentMethod = noATCFulfillment ? null
    : getFulfillmentMethod(product, hasInStoreFilter);
  const fulfillmentLocation = noATCFulfillment ? null
    : getFulfillmentLocation({ fulfillmentMethod, store, product, deliveryZipCode });

  const { frequency, isOptIn } = subscriptionInfo || {};
  const subscriptionObj = {
    subscriptions: { frequency, ogModule: 'pdp_nocontent' }
  };

  if (fulfillmentMethod === FULFILLMENT_METHOD_BOPIS) {
    return {
      itemId,
      host: getHostName(),
      keyword: fulfillmentLocation,
      channel,
      quantity,
      alterBrowserHistory: true,
      isShipToHomeEligible: false,
      fulfillmentMethod,
      fulfillmentLocation
    };
  }

  return {
    itemId,
    quantity,
    fulfillmentMethod,
    fulfillmentLocation,
    ...(isOptIn && { ...subscriptionObj })
  };
};

export const getCartOptions = ({
  channel, hidden, sharedSection = '', misship
}) => {
  return {
    host: getHostName(),
    channel,
    paypal: false,
    misship,
    bypassBss: misship,
    bss: misship,
    hidden,
    sharedSection,
  };
};
