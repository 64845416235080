import React, { useEffect } from 'react';
import { shape } from 'prop-types';
import loadjs from 'loadjs';

const RevJetScript = (props) => {
  const { tagProperties } = props;

  useEffect(() => {
    let revTagObject;

    const loadRevJet = () => {
      window.RevjetBgPromise = new Promise((resolve) => {
        loadjs(['https://rna.homedepot.com/bg'], {
          success: resolve,
          error: resolve,
          async: false
        });
      }).then(() => {

        if (tagProperties && tagProperties._tag && tagProperties._key && window.REVJET_Tag) {

          revTagObject = window.REVJET_Tag({
            ...tagProperties,
            _opts: {
              ...tagProperties._opts,
              embd_tag_id: 'revjet-content-body'
            }
          });

        }
      });

    };
    /* eslint-disable-next-line no-unused-expressions */
    !window.RevjetBgPromise && loadRevJet();

    return () => {
      if (revTagObject) {
        revTagObject.destroy();
      }
    };

  }, []);

  return (
    <div id="revjet-content-body" data-component="RevJetScript" />
  );
};

export default RevJetScript;

RevJetScript.propTypes = {
  tagProperties: shape({}).isRequired
};

RevJetScript.displayName = 'RevJetScript';